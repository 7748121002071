import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';
import { operatorsList } from './operators';
import { Text } from './Types';
import { match } from 'ts-pattern';

const DEFAULT_NEW_FILTER = {
    key: 0,
    id: '',
    label: '',
    property: '',
    operator: '',
    value: '',
    valueList: null,
    ruleable: false,
    esUrlFilter: '',
    sqlUrlFilter: '',
};

const getUrlFilter = ({ id, property, operator }, formattedValue, dataSource: DataSource) => {
    return match(dataSource)
        .with(DataSource.ES, () =>
            operator.buildESFilter ? operator.buildESFilter(property || id, formattedValue) : ''
        )
        .with(DataSource.SQL, () => (operator.buildFilter ? operator.buildFilter(property || id, formattedValue) : ''))
        .exhaustive();
};

const formatConfigurations = configuration => {
    const configurationMap = {};
    configuration.forEach(c => (configurationMap[c.id] = c.type ? c : { ...c, type: Text }));
    return { map: configurationMap, list: configuration };
};

const addConfigurationParams = (filter, confMap) => {
    const newFilter = {
        ...filter,
        label: confMap.label,
        ruleable: !!confMap.ruleable,
        property: confMap.property || filter.property,
    };
    return {
        ...newFilter,
        esUrlFilter: getUrlFilter(newFilter, confMap.type.formatES(filter.value), DataSource.ES),
        sqlUrlFilter: getUrlFilter(newFilter, confMap.type.format(filter.value), DataSource.SQL),
    };
};

const getKey = () => 'filter_' + Date.now().toString(36) + Math.random().toString(36).replace('.', '');

const getNewFilterItem = (filter = {}) => {
    return { ...DEFAULT_NEW_FILTER, key: getKey(), ...filter };
};

const createFiltersFromBasicData = (basicFilters, configuration) => {
    const { map: confs } = formatConfigurations(configuration);
    const filters = basicFilters.map(bf =>
        addConfigurationParams(getNewFilterItem({ ...bf, operator: operatorsList[bf.operator] }), confs[bf.id])
    );
    return filters;
};

const createFiltersFromSearchParams = (searchFilters, configuration) => {
    const { map: confs } = formatConfigurations(configuration);
    const basicFilters = Object.values(searchFilters).map(e => {
        const filterParsed = JSON.parse(e as string);
        return {
            id: filterParsed.id,
            operator: filterParsed.operator,
            value: filterParsed.value,
            valueList: filterParsed.valueList,
        };
    });
    const filters = basicFilters.map(bf =>
        addConfigurationParams(getNewFilterItem({ ...bf, operator: operatorsList[bf.operator] }), confs[bf.id])
    );
    return filters;
};

const operatorsMap = {
    Equal: 'eq',
    Contains: 'contains',
    NotContains: 'notContains',
    MappedBy: 'mappedBy',
};

export {
    operatorsMap,
    formatConfigurations,
    addConfigurationParams,
    getNewFilterItem,
    getUrlFilter,
    createFiltersFromBasicData,
    createFiltersFromSearchParams,
};
