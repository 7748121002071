import { requestNewVehicleCounts, requestActiveFlagsCount } from 'api/RepairProcedureApi';

const fetchCounts = async context => {
    try {
        const [newVehicleResponse, activeFlagsResponse] = await Promise.all([
            requestNewVehicleCounts(),
            requestActiveFlagsCount(),
        ]);

        switch (context) {
            case 'tagger': {
                // Transform array response to an object
                const activeFlagsCounts = activeFlagsResponse.activeFlagsCounts.reduce((acc, { oemId, count }) => {
                    acc[oemId] = { activeFlagsCount: count };
                    return acc;
                }, {});
                return activeFlagsCounts; // Return transformed active flags counts for tagger
            }
            case 'ingestionManager':
                return newVehicleResponse; // Return new vehicle counts
            case 'toolSelection':
                return newVehicleResponse.total; // Return total count for tool selection
            default:
                throw new Error(`Unknown context: ${context}`);
        }
    } catch (error) {
        console.error('Error fetching counts:', error);
        return {};
    }
};

export default fetchCounts;
