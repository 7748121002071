import { Operator } from 'components/locations/MappingProcess/RulesCreator/RulableValuePicker/types';

const bookNameField = 'booksForProcedure.book.bookName';
const bookIdField = 'booksForProcedure.book.bookId';

const escapeSpecialChars = str => {
    if (typeof str !== 'string' && !(str instanceof String)) return str;

    const specialChars = /[+\-=&|><!(){}[\]^"~*?:\\/\s@#]/g;
    return str.replace(specialChars, '\\$&');
};

export const ManuallyMapped = {
    INCLUDE: 'include',
    EXCLUDE: 'exclude',
};

const mappedByBuildSQLFilter = (propertyName, value) => {
    const filters = [];
    const { rulesIds, manuallyMapped } = value;
    const values = rulesIds.map(v => v.value);
    if (manuallyMapped != null) {
        let filter = `(${propertyName}/type/mappingRuleId eq null or ${propertyName}/groups/any(g : g/mappingRuleId eq null))`;
        if (manuallyMapped === ManuallyMapped.EXCLUDE) {
            filter = 'not ' + filter;
        }
        filters.push(filter);
    }

    if (values.length > 0) {
        const inList = values.join(',');
        filters.push(
            `(${propertyName}/type/mappingRuleId in (${inList}) or ${propertyName}/groups/any(g : g/mappingRuleId in (${inList})))`
        );
    }
    return `(${filters.join(' and ')})`;
};

function createODataFilterFunction(operator) {
    const getODataField = field => field.replaceAll('.', '/');
    const getODataValue = value => {
        const oDataValue =
            isNaN(value) && (typeof value === 'string' || value instanceof String)
                ? encodeURIComponent(`'${value.slice(1, -1).replaceAll("'", "''")}'`)
                : value;
        return oDataValue;
    };
    switch (operator) {
        case 'contains':
            return (field, value) => `contains(${getODataField(field)}, ${getODataValue(value)})`;
        case 'not contains':
            return (field, value) => `not contains(${getODataField(field)}, ${getODataValue(value)})`;
        default:
            return (field, value) => `${getODataField(field)} ${operator} ${getODataValue(value)}`;
    }
}

const mappedByBuildESFilter = (propertyName, value) => {
    const filters = [];
    const { rulesIds, manuallyMapped } = value;
    const values = rulesIds.map(v => v.value);

    if (manuallyMapped != null) {
        if (manuallyMapped === ManuallyMapped.EXCLUDE) {
            filters.push(`(!${propertyName}.type.mappingRuleId:null) && (${propertyName}.groups.mappingRuleId:*)`);
        } else {
            filters.push(`(${propertyName}.type.mappingRuleId:null) || (!${propertyName}.groups.mappingRuleId:*)`);
        }
    }

    if (values.length > 0) {
        const inList = values.join(' OR ');
        filters.push(
            `(${propertyName}.type.mappingRuleId:(${inList}) || ${propertyName}.groups.mappingRuleId:(${inList}))`
        );
    }

    return filters.join(' && ');
};

const operatorsList = {
    contains: {
        value: 'contains',
        label: 'contains',
        buildESFilter: (field, value) => `${field}:*${escapeSpecialChars(value)}*`,
        buildFilter: createODataFilterFunction('contains'),
        ruleableValue: Operator.Contains,
    },
    containsBookName: {
        value: 'containsbookname',
        label: 'contains',
        buildESFilter: (field, value) => `${bookNameField}:*${escapeSpecialChars(value)}*`,
        buildFilter: (propertyName, value) =>
            `BooksForProcedure/any(bp: contains(bp/Book/BookName, ${encodeURIComponent(value)}))`,
        ruleableValue: Operator.Contains,
    },
    notContains: {
        value: 'notContains',
        label: 'not contains',
        buildESFilter: (field, value) => `!${field}:*${escapeSpecialChars(value)}*`,
        buildFilter: createODataFilterFunction('not contains'),
        ruleableValue: Operator.NotContains,
    },
    notContainsBookName: {
        value: 'notcontainsbookname',
        label: 'not contains',
        buildESFilter: (field, value) => `!${bookNameField}:*${escapeSpecialChars(value)}*`,
        buildFilter: (propertyName, value) =>
            `not BooksForProcedure/any(bp: contains(bp/Book/BookName, ${encodeURIComponent(value)}))`,
        ruleableValue: Operator.NotContains,
    },
    eq: {
        value: 'eq',
        label: '==',
        buildFilter: createODataFilterFunction('eq'),
        buildESFilter: (field, value) => `${field}:${escapeSpecialChars(value)}`,
        ruleableValue: Operator.Equal,
    },
    eqBookId: {
        value: 'eqbookid',
        label: '==',
        buildFilter: (propertyName, value) => `BooksForProcedure/any(bp: bp/BookId eq ${value})`,
        buildESFilter: (field, value) => `${bookIdField}:${value}`,
        ruleableValue: Operator.Equal,
    },
    eqBookName: {
        value: 'eqbookname',
        label: '==',
        buildESFilter: (field, value) => `${bookNameField}:${escapeSpecialChars(value)}`,
        buildFilter: (propertyName, value) =>
            `BooksForProcedure/any(bp: bp/Book/BookName eq ${encodeURIComponent(value)})`,
        ruleableValue: Operator.Equal,
    },
    ne: {
        value: 'ne',
        label: '!=',
        buildESFilter: (field, value) => `!${field}:${escapeSpecialChars(value)}`,
        buildFilter: createODataFilterFunction('ne'),
    },
    neBookName: {
        value: 'nebookname',
        label: '!=',
        buildESFilter: (field, value) => `!${bookNameField}:${escapeSpecialChars(value)}`,
        buildFilter: (propertyName, value) =>
            `BooksForProcedure/any(bp: bp/Book/BookName ne ${encodeURIComponent(value)})`,
    },
    lt: {
        value: 'lt',
        label: '<',
        buildESFilter: (field, value) => `${field}:<${value}`,
        buildFilter: createODataFilterFunction('lt'),
    },
    le: {
        value: 'le',
        label: '<=',
        buildESFilter: (field, value) => `${field}:<=${value}`,
        buildFilter: createODataFilterFunction('le'),
    },
    gt: {
        value: 'gt',
        label: '>',
        buildESFilter: (field, value) => `${field}:>${value}`,
        buildFilter: createODataFilterFunction('gt'),
    },
    ge: {
        value: 'ge',
        label: '>=',
        buildESFilter: (field, value) => `${field}:>=${value}`,
        buildFilter: createODataFilterFunction('ge'),
    },
    mappedBy: {
        value: 'mappedBy',
        label: 'Mapped by',
        buildESFilter: mappedByBuildESFilter,
        buildFilter: mappedByBuildSQLFilter,
    },
};

const ruleableOperators = [operatorsList.eq, operatorsList.contains, operatorsList.notContains];

const all = Object.entries(operatorsList);

const isFilterRulable = (filter, operator = {}) =>
    filter.ruleable && ruleableOperators.some(o => o.value === (operator.value || filter.operator.value));

export { operatorsList, all, isFilterRulable, escapeSpecialChars };
