import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    requestFordBulkSetShouldVehicleBeRun,
    requestFordScrapeManagerVehicleOdata,
    requestFordSetShouldVehicleBeRun,
} from 'api/RepairProcedureApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { FordVehicle, IngestionButton } from '../types';

const useFordVehicles = notifications => {
    const [vehicles, setVehicles] = useState<FordVehicle[]>([]);
    const [filters, setFilters] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    const page = useRef(0);
    const pageSize = 100;

    const loadVehicles = useCallback(async () => {
        if (!hasMore) return;
        try {
            setLoading(true);
            incrementLoading();

            const vehicles = await getVehicles(page.current, pageSize, filters);
            page.current++;

            if (vehicles.data.length < pageSize) setHasMore(false);

            if (vehicles.data) {
                setVehicles(prevVehicles => {
                    const newVehicles = vehicles.data.filter(
                        veh =>
                            !prevVehicles.some(
                                prevVehicle => prevVehicle.fordScrapeManagerVehicleId === veh.fordScrapeManagerVehicleId
                            )
                    );
                    return [...prevVehicles, ...newVehicles];
                });
            }
        } catch (e) {
            notifications.pushExceptionDanger(e);
        } finally {
            setLoading(false);
            decrementLoading();
        }
    }, [incrementLoading, filters, setVehicles, notifications, decrementLoading, hasMore]);

    useEffect(() => {
        const getInitialVehicles = async () => {
            try {
                setLoading(true);
                incrementLoading();

                page.current = 0;

                const vehicles = await getVehicles(page.current, pageSize, filters);

                if (vehicles.data.length < pageSize) {
                    setHasMore(false);
                }

                if (vehicles.error) {
                    showToast(vehicles.error);
                }
                setVehicles(vehicles.data);
                page.current++;
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                setLoading(false);
                decrementLoading();
            }
        };
        getInitialVehicles();
    }, [decrementLoading, filters, incrementLoading, notifications, setVehicles, showToast]);

    const turnFordVehiclesOn = useCallback(
        async (fordScrapeManagerVehicleId: number) => {
            try {
                await requestFordSetShouldVehicleBeRun(fordScrapeManagerVehicleId, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        v.fordScrapeManagerVehicleId === fordScrapeManagerVehicleId
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications]
    );

    const turnFordVehiclesOff = useCallback(
        async (fordScrapeManagerVehicleId: number) => {
            try {
                await requestFordSetShouldVehicleBeRun(fordScrapeManagerVehicleId, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        v.fordScrapeManagerVehicleId === fordScrapeManagerVehicleId
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, setVehicles]
    );

    const bulkTurnFordVehiclesOn = useCallback(
        async (ids: number[]) => {
            try {
                await requestFordBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.fordScrapeManagerVehicleId)
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, setVehicles]
    );

    const bulkTurnFordVehiclesOff = useCallback(
        async (ids: number[]) => {
            try {
                await requestFordBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.fordScrapeManagerVehicleId)
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, setVehicles]
    );

    const onSearch = (filter: string, radioValue: IngestionButton) => {
        const yearNameFilter = /^\d+$/.test(filter) ? `yearName eq ${filter}` : '';
        const modelNameFilter = `contains(modelName,'${filter}')`;
        const notesFilter = `contains(notes,'${filter}')`;

        const processFilter =
            radioValue === IngestionButton.NewButton
                ? 'and (hasBeenReviewed eq false)'
                : radioValue === IngestionButton.OnButton
                ? 'and (hasBeenReviewed eq true and shouldVehicleBeRun eq true)'
                : radioValue === IngestionButton.OffButton
                ? 'and (hasBeenReviewed eq true and shouldVehicleBeRun eq false)'
                : '';

        const parsedFilters = `(${
            yearNameFilter && `${yearNameFilter} or`
        } ${modelNameFilter} or ${notesFilter}) ${processFilter}`;

        setFilters(parsedFilters);
    };

    return {
        vehicles,
        isLoading: loading,
        loadMoreCallback: loadVehicles,
        onSearch,
        turnFordVehiclesOn,
        turnFordVehiclesOff,
        bulkTurnFordVehiclesOn,
        bulkTurnFordVehiclesOff,
        hasMore,
    };
};

const getVehicles = async (page: number, pageSize: number, filter: string) => {
    try {
        const vehicles = await requestFordScrapeManagerVehicleOdata({
            top: pageSize,
            skip: pageSize * page,
            filter: `isDeleted eq false and hasWorkshopManual eq true ${filter ? ` and (${filter})` : ''}`,
        });
        return { data: vehicles.value, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

export default useFordVehicles;
