import React, { useCallback } from 'react';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import SmallSpinner from 'components/SmallSpinner';

const BookItem = ({
    book,
    bookDetails,
    displayTaggerDetails,
    isNotCompletedFilter,
    isRefreshedPendingFilter,
    isOnlyHotSheetFilter,
    procedureTools,
}) => {
    let navigate = useNavigate();
    let location = useLocation();

    // stats will be removed soon
    const stats = bookDetails?.stats;
    const statistics = bookDetails?.statistics;

    const filterNotCompleted = () => {
        return !isNotCompletedFilter || (bookDetails && bookDetails.stats.mappedPercentage !== 100);
    };

    const filterRefreshedPending = () => {
        return !isRefreshedPendingFilter || (bookDetails && bookDetails.stats.totalLatestMajorProceduresToPublish > 0);
    };

    const filterOnlyHotSheet = () => {
        return !isOnlyHotSheetFilter || (bookDetails && bookDetails.statistics.onlyHotSheetMapped > 0);
    };

    const isShown = displayTaggerDetails
        ? filterRefreshedPending() && filterOnlyHotSheet()
        : filterNotCompleted() && filterRefreshedPending() && filterOnlyHotSheet();

    const navigateToProceduresPage = useCallback(() => {
        const path = location.pathname;
        const parts = path.split('/');
        const filteredParts = parts.slice(0, parts.length - 1);
        const newPath = filteredParts.join('/');

        const filters = [
            { id: 'firstBookId', operator: 'eqBookId', value: book.bookId },
            { id: 'firstBookName', operator: 'eqBookName', value: book.bookName },
        ];
        const searchParametersFilters = createSearchParams(
            filters.reduce((p, c) => ({ ...p, [c.id]: JSON.stringify(c) }), {})
        );

        navigate(`${newPath}?${searchParametersFilters.toString()}`);
    }, [location, navigate, book]);

    return (
        <tr
            className={`clickable${isShown ? '' : ' d-none'}`}
            onClick={() =>
                procedureTools ? navigateToProceduresPage() : navigate(`${location.pathname}/${book.bookId}`)
            }>
            <td data-testid="bookName">{book.bookName}</td>
            {displayTaggerDetails ? (
                <>
                    {!bookDetails ? (
                        <td colSpan={7}>
                            <SmallSpinner />
                        </td>
                    ) : (
                        <>
                            <td className="text-center">{`${stats.activeFlagsCount}`}</td>
                            <td className="text-center">{`${stats.inProgressActiveTagsCount}`}</td>
                            <td className="text-center">{`${stats.needsHelpActiveTagsCount}`}</td>
                            <td className="text-center">{`${stats.inReviewActiveTagsCount}`}</td>
                            <td className="text-center">{`${stats.completeActiveTagsCount}`}</td>
                            <td className="text-center">{`${stats.uncheckedLinksCount}`}</td>
                            <td className="text-center">{`${stats.checkedLinksCount}`}</td>
                        </>
                    )}
                </>
            ) : (
                <>
                    {!bookDetails ? (
                        <td colSpan={3}>
                            <SmallSpinner />
                        </td>
                    ) : (
                        <>
                            <td>{statistics.removedPending}</td>
                            <td>{statistics.onlyHotSheetMapped}</td>
                            <td>{`${statistics.groupsInReview} (${statistics.groupsInReviewPercentage.toFixed(
                                2
                            )}%)`}</td>
                            <td>{`${statistics.typesInReview} (${statistics.typesInReviewPercentage.toFixed(2)}%)`}</td>
                            <td>{`${statistics.groupsCompleted} (${statistics.groupsCompletedPercentage.toFixed(
                                2
                            )}%)`}</td>
                            <td>{`${statistics.typesCompleted} (${statistics.typesCompletedPercentage.toFixed(
                                2
                            )}%)`}</td>
                            <td>{`${statistics.needHelp} (${statistics.needHelpPercentage.toFixed(2)}%)`}</td>
                        </>
                    )}
                </>
            )}
        </tr>
    );
};

export default React.memo(BookItem);
