import React from 'react';
import type { CellProps, ItemReturnEvent } from 'components/Shared/Table/types';
import { ImportHistoryActionsTypes } from '../types';

const ForceCompleteCell: React.FC<CellProps> = ({ item, index, idKey, cellEventCallback }) => {
    const buttonId = `import-history-force-button-${item.importJobId}`;

    const handleForceCompleteClick = () => {
        const payload: ItemReturnEvent = {
            id: item[idKey] as string,
            item: item,
            index: index,
            actionId: ImportHistoryActionsTypes.ForceComplete,
        };

        cellEventCallback(payload);
    };

    return (
        <td className="d-flex justify-content-start" style={{ marginLeft: '-15px' }}>
            <button id={buttonId} data-testid={buttonId} className="btn btn-link" onClick={handleForceCompleteClick}>
                <span>
                    <u>{"Don't Click"}</u>
                </span>
            </button>
        </td>
    );
};

export default ForceCompleteCell;
