import { Route, Routes } from 'react-router-dom';
import Oems from 'components/Oem/Oems';
import ToolSelection from 'components/locations/Home/ToolSelection';
import AuthorizedElement from 'routes/AuthorizedElement';
import StyleGuide from './StyleGuide/StyleGuide';
import PlansReport from './Reporting/PlansReport/PlansReport';
import GraphExplorer from './GraphExplorer/GraphExplorer';
import BooksListMapper from 'components/BookList/BooksListMapper';

const OtherRoutes = () => {
    return (
        <Routes>
            <Route
                path={'/'}
                element={
                    <ToolSelection
                        tools={[
                            {
                                path: '/other/style-guide',
                                text: 'Style Guide',
                                icon: 'palette',
                                tileDescription:
                                    'Future home of all components related to the RepairLogic look and feel. Currently under development',
                                access: 'tool.other.styleGuide',
                            },
                            {
                                path: '/other/report-plans',
                                text: 'Report Repair Plans',
                                icon: 'sitemap',
                                tileDescription: 'Report for Repair Plans',
                                access: 'tool.other.reporting',
                            },
                            {
                                path: '/other/graph',
                                text: 'Graph Explorer',
                                icon: 'project-diagram',
                                tileDescription:
                                    'Want to see how interconnected the Repair Procedures are? Graph Explorer is the place to be.',
                                access: 'tool.other.graphExplorer',
                            },
                        ]}
                    />
                }
            />
            <Route
                path="/style-guide"
                element={
                    <AuthorizedElement resource={'tool.other.styleGuide'}>
                        <StyleGuide />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/report-plans"
                element={
                    <AuthorizedElement resource={'tool.other.reporting'}>
                        <PlansReport />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/graph"
                element={
                    <AuthorizedElement resource={'tool.other.graphExplorer'}>
                        <Oems />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/graph/:oemId"
                element={
                    <AuthorizedElement resource={'tool.other.graphExplorer'}>
                        <BooksListMapper />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/graph/:oemId/:bookId"
                element={
                    <AuthorizedElement resource={'tool.other.graphExplorer'}>
                        <GraphExplorer />
                    </AuthorizedElement>
                }
            />
        </Routes>
    );
};

export default OtherRoutes;
