import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, createSearchParams } from 'react-router-dom';
import { Text, ActionFloatingMenuCell } from 'components/Shared/Table/Cells';
import { ImportJobBook, ImportJobBookStats, ImportJobDetailsRowDataType, ImportJobDetailsActionsTypes } from './types';
import {
    requestMappingStatistics,
    requestImportJobDetails,
    requestReimportBook,
    requestRetryImportJob,
    requestForceImportJobComplete,
    requestRequeueBookForFlagging,
} from 'api/RepairProcedureApi';
import { BookCell } from './CustomCells';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import useOems from 'hooks/useOems';
import { FormatDateToHumanReadable } from 'helpers/DateHelper';
import { ToastContext } from 'components/ToastProvider';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import type { CellProps, DataItem, ItemReturnEvent } from 'components/Shared/Table/types';
import { LoadingContext } from 'components/Layout';
import type { ImportJob } from '../types';
import { TABLE_COLUMN_ENUM, OPERATOR_TYPE_ENUM } from 'helpers/BulkEditTableHelper';

const BATCH_SIZE = 20;

export const IMPORT_FILTER = {
    All: 'all',
    Successful: 'true',
    Failed: 'false',
};

const getImportJobDetails = async (
    importJobId: string,
    top: number,
    skip: number,
    searchValue?: string,
    filterValue?: string
): Promise<{ importJob: ImportJob; totalBooks: number; books: ImportJobBook[] } | null> => {
    const details = await requestImportJobDetails(
        importJobId,
        top,
        skip,
        searchValue,
        filterValue !== IMPORT_FILTER.All ? filterValue : null
    );
    if (!details || !details.length) {
        return null;
    }
    const detail = details[0];
    const total = detail['books@odata.count'] as number;
    const books = detail.books as ImportJobBook[];
    const importJob: ImportJob = {
        importJobId: detail.importJobId,
        oemId: detail.oemId,
        userId: detail.userId ?? null,
        createDate: detail.createDate,
        updateDate: detail.updateDate ?? null,
        numberOfBooks: detail.numberOfBooks ?? null,
    };
    return { importJob: importJob, totalBooks: total, books: books };
};

const useImportJobDetails = () => {
    const [importJob, setImportJob] = useState<ImportJob | null>(null);
    const [importJobBooks, setImportJobBooks] = useState<ImportJobBook[]>([]);
    const [importJobBookStats, setImportJobBookStats] = useState(new Map<string, ImportJobBookStats | null>());
    const [totalBooks, setTotalBooks] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [showImportJobBookCommandModal, setShowImportJobBookCommandModal] = useState<boolean>(false);
    const [importJobBookCommandId, setImportJobBookCommandId] = useState<string>('');
    const [retryImportModalOpen, setRetryImportModalOpen] = useState(false);
    const defaultToggles = useMemo(
        () => ({
            shouldReCrawl: false,
            shouldReImport: false,
            shouldReImportAll: false,
            force: false,
        }),
        []
    );
    const [toggles, setToggles] = useState(defaultToggles);

    const { oems } = useOems();

    const { hasAccess } = useContext(AccessControlContext);
    const { hasRole } = useContext(AccessControlContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);
    const { notifications } = useContext(NotificationsContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const confirmationRef = useRef(null);
    const { importJobId } = useParams();
    const [importFilter, setImportFilter] = useState(IMPORT_FILTER.All);

    const canRunRetryImportJob = useMemo(() => hasRole(roles.admin) || hasRole(roles.siteAdmin), [hasRole]);

    const navigate = useNavigate();

    const getAndSetImportJobDetails = useCallback(
        async (searchValue, importFilter) => {
            try {
                setLoading(true);
                const details = await getImportJobDetails(importJobId, BATCH_SIZE, 0, searchValue, importFilter);
                if (details) {
                    setTotalBooks(details.totalBooks);
                    setImportJob(details.importJob);
                    setImportJobBooks(details.books);
                }
            } catch (error) {
                showToast(error);
            } finally {
                setLoading(false);
            }
        },
        [importJobId, showToast]
    );

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            if (!isUnmounted) await getAndSetImportJobDetails(null, null);
        })();

        return () => {
            isUnmounted = true;
        };
    }, [importJobId, showToast, getAndSetImportJobDetails]);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                for (let i = 0; i < importJobBooks.length && !isUnmounted; i++) {
                    const { importJobBookId, rpBookId } = importJobBooks[i];

                    if (importJobBookStats.has(importJobBookId)) continue;

                    let jobBookStats: ImportJobBookStats = null;
                    if (rpBookId !== null) {
                        const mappingStats = await requestMappingStatistics(rpBookId);
                        const percentage = (
                            (mappingStats.proceduresCount.completed / mappingStats.proceduresCount.total) *
                            100
                        ).toFixed(2);
                        jobBookStats = {
                            mappingStatus: `${mappingStats.proceduresCount.completed}/${mappingStats.proceduresCount.total} (${percentage}%)`,
                        };
                    }

                    if (!isUnmounted) {
                        setImportJobBookStats(prev => {
                            const newStats = new Map(prev);
                            newStats.set(importJobBookId, jobBookStats);
                            return newStats;
                        });
                    }
                }
            } catch (error) {
                showToast(error);
            }
        })();
        return () => {
            isUnmounted = true;
        };
    }, [importJobBookStats, importJobBooks, showToast]);

    const fetchMore = useCallback(async () => {
        try {
            setLoading(true);

            const details = await getImportJobDetails(
                importJobId,
                BATCH_SIZE,
                importJobBooks.length,
                searchValue,
                importFilter
            );

            if (!details || !details.books.length) {
                // if we cannot get more books for some reason, stop the process
                throw new Error('Cannot fetch more books, rip ☠️');
            } else {
                const books = details.books;
                setImportJobBooks(prev => [...prev, ...books]);
            }
        } catch (error) {
            // if we cannot get more books for some reason, stop the process
            setTotalBooks(0);
            showToast(error);
        } finally {
            setLoading(false);
        }
    }, [importJobId, importJobBooks.length, searchValue, showToast, importFilter]);

    const handleGoToTags = useCallback(
        (oemId, bookFriendlyName) => {
            navigate(`/tagging-process/tagger/${oemId}/bulkedit`, {
                state: {
                    filters: [
                        {
                            columnId: TABLE_COLUMN_ENUM.BOOK_NAME,
                            operator: OPERATOR_TYPE_ENUM.CONTAINS,
                            inputValue: bookFriendlyName,
                        },
                    ],
                },
            });
        },
        [navigate]
    );
    const headers = useMemo(() => {
        const headers: Array<{ label: string; id?: string; component?: React.FC<CellProps> }> = [
            {
                label: 'Book Name',
                id: 'bookFriendlyName',
                component: BookCell,
            },
            {
                label: 'Success',
                id: 'success',
                component: Text({
                    transform: i => (i.success === true ? 'True' : i.success === false ? 'False' : '-'),
                }),
            },
            {
                label: 'Flagging Success',
                id: 'flaggingSuccess',
                component: Text({
                    transform: i => (i.flaggingSuccess === true ? 'True' : i.flaggingSuccess === false ? 'False' : '-'),
                }),
            },
            {
                label: 'New Procedures',
                id: 'numOfNewProcedures',
            },
            {
                label: 'Refreshed Pending',
                id: 'numOfRefreshedProcedures',
            },
            {
                label: 'Removed',
                id: 'numOfRemovedProcedures',
            },
            {
                label: 'Status',
                id: 'stats.mappingStatus',
            },
            {
                label: 'Flags',
                id: 'numOfFlagsFound',
            },
            {
                label: 'Tags In Refreshed Pending',
                id: 'numOfExistingTagsInRefreshedProcedures',
            },
        ];
        if (hasAccess('importManager.runBook')) {
            const actions = {
                buttons: [
                    {
                        id: ImportJobDetailsActionsTypes.RunBook,
                        class: 'btn-primary flex-grow-1',
                        text: 'Run Book',
                        title: 'Queue up a re-importing task for this book',
                        disabled: (item: DataItem) =>
                            !(item as { serializedImportJobBookCommand: unknown }).serializedImportJobBookCommand,
                    },
                    {
                        id: ImportJobDetailsActionsTypes.ViewImportJobBookCommand,
                        class: 'btn-primary flex-grow-1',
                        text: 'View',
                        icon: 'filter',
                        title: 'View Import Job Book Command',
                    },
                    {
                        id: ImportJobDetailsActionsTypes.ViewProcedure,
                        class: 'btn-primary flex-grow-1',
                        text: 'View Procedures',
                        title: 'View Book Procedures',
                        disabled: (item: DataItem) =>
                            !(item as { success: boolean; rpBookId: number }).success ||
                            (item as { success: boolean; rpBookId: number }).rpBookId === null,
                    },
                    {
                        id: ImportJobDetailsActionsTypes.GoToTags,
                        class: 'btn-primary flex-grow-1',
                        text: 'Go to Tags',
                        title: 'Go to the BulkEdit page with Book Name prefilled in search bar',
                        disabled: (item: DataItem) => !(item as { rpBookId: number }).rpBookId,
                    },
                    {
                        id: ImportJobDetailsActionsTypes.RequeueFlagging,
                        class: 'btn-primary flex-grow-1',
                        text: 'Requeue Flagging',
                        title: 'Requeue Book for Flagging',
                    },
                ],
            };

            const floatingMenu = ActionFloatingMenuCell({
                floatingMenuId: 'import-job-actions',
                labelId: 'import-job-actions-button',
                options: actions,
            });

            const action = {
                id: 'actions',
                label: 'Actions',
                component: floatingMenu,
            };

            headers.push(action);
        }

        return headers;
    }, [hasAccess]);

    const handleRunBookButtonClick = useCallback(
        (payload: ItemReturnEvent) => {
            if (confirmationRef.current) {
                confirmationRef.current.open(
                    async () => {
                        try {
                            incrementLoading();
                            const item = payload.item as ImportJobDetailsRowDataType;
                            await requestReimportBook(item.importJobBookId);
                            // manually set import job book and import job book stats after the action is queued up
                            // since it might not be a live update (queue)
                            setImportJobBookStats(prev => {
                                const stats = new Map(prev);
                                stats.set(item.importJobBookId, null);
                                return stats;
                            });
                            setImportJobBooks(prev =>
                                prev.map(book =>
                                    book.importJobBookId !== item.importJobBookId
                                        ? book
                                        : { ...book, success: null, rpBookId: null }
                                )
                            );
                        } catch (error) {
                            showToast(error);
                        } finally {
                            decrementLoading();
                        }
                    },
                    {
                        title: 'Confirmation',
                        body: `Are you sure to run the importer for ${payload.item.bookFriendlyName} book? Have a ☕ ready?`,
                    }
                );
            }
        },
        [incrementLoading, decrementLoading, showToast]
    );

    const handleOpenCommandModalClick = useCallback(
        async (payload: ItemReturnEvent) => {
            const item = payload.item as ImportJobDetailsRowDataType;
            setShowImportJobBookCommandModal(true);
            setImportJobBookCommandId(item.importJobBookId);
        },
        [setShowImportJobBookCommandModal, setImportJobBookCommandId]
    );

    const handleForceCompleteButtonClick = useCallback(() => {
        if (confirmationRef.current && importJob) {
            confirmationRef.current.open(
                async () => {
                    try {
                        await requestForceImportJobComplete(importJob.importJobId);
                        notifications.pushSuccess(`Successfully set Import Job ${importJob.importJobId} to complete`);
                    } catch (error) {
                        showToast(error);
                    }
                },
                {
                    title: 'Confirmation',
                    body: `Are you sure you want to force complete Import Job ${importJob.importJobId}?`,
                }
            );
        }
    }, [importJob, notifications, showToast]);

    const handleViewProceduresClick = useCallback(
        (payload: ItemReturnEvent) => {
            const item = payload.item as ImportJobDetailsRowDataType;
            const oem = importJob.oemId;
            const filter = [{ id: 'firstBookId', operator: 'eqBookId', value: item.rpBookId }];

            const searchParamsFilters = createSearchParams(
                Object.keys(filter).reduce((p, key) => {
                    p[key] = JSON.stringify(filter[key]);
                    return p;
                }, {})
            );

            const destination = `/mapping-process/procedures/${oem}?${searchParamsFilters.toString()}`;
            navigate(destination);
        },
        [importJob, navigate]
    );

    const handleGoToTagsButtonClick = useCallback(
        (payload: ItemReturnEvent) => {
            const item = payload.item as ImportJobDetailsRowDataType;
            if (importJob) {
                handleGoToTags(importJob.oemId, item.bookFriendlyName);
            }
        },
        [handleGoToTags, importJob]
    );

    const handleRequeueFlaggingClick = useCallback(
        async (payload: ItemReturnEvent) => {
            const item = payload.item as ImportJobDetailsRowDataType;

            try {
                setLoading(true);

                const command = {
                    BookId: item.rpBookId,
                    ImportJobBookId: item.importJobBookId,
                    SkipDeletingFlags: false,
                };

                await requestRequeueBookForFlagging(command);

                notifications.pushSuccess('Successfully requeued book for flagging');
            } catch (error) {
                showToast('Failed to requeue book for flagging');
            } finally {
                setLoading(false);
            }
        },
        [notifications, showToast]
    );

    const handlers = useMemo(
        () => ({
            [ImportJobDetailsActionsTypes.RunBook]: handleRunBookButtonClick,
            [ImportJobDetailsActionsTypes.ViewImportJobBookCommand]: handleOpenCommandModalClick,
            [ImportJobDetailsActionsTypes.ViewProcedure]: handleViewProceduresClick,
            [ImportJobDetailsActionsTypes.GoToTags]: handleGoToTagsButtonClick,
            [ImportJobDetailsActionsTypes.RequeueFlagging]: handleRequeueFlaggingClick,
        }),
        [
            handleRunBookButtonClick,
            handleOpenCommandModalClick,
            handleViewProceduresClick,
            handleGoToTagsButtonClick,
            handleRequeueFlaggingClick,
        ]
    );

    const handleSearchValueChange = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    const handleSearchKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }, []);

    const handleSearchBlur = useCallback(
        async (e?: React.FocusEvent<HTMLInputElement, Element>): Promise<void> => {
            const value = e?.currentTarget.value.trim() || '';

            setSearchValue(value);
            setLoading(true);
            setTotalBooks(0);
            setImportJobBooks([]);
            setImportJobBookStats(new Map());
            await getAndSetImportJobDetails(value, importFilter);
        },
        [importFilter, getAndSetImportJobDetails]
    );

    const handleImportFilterChange = useCallback(
        async e => {
            setImportFilter(e);
            await getAndSetImportJobDetails(searchValue, e);
        },
        [searchValue, getAndSetImportJobDetails]
    );

    const data: ImportJobDetailsRowDataType[] = useMemo(
        () =>
            importJobBooks.map(ij => {
                const row: ImportJobDetailsRowDataType = { ...ij, stats: null };
                if (importJobBookStats.has(row.importJobBookId)) {
                    const stats = importJobBookStats.get(row.importJobBookId);
                    row.stats = stats ? { ...importJobBookStats.get(row.importJobBookId) } : null;
                }
                return row;
            }),
        [importJobBooks, importJobBookStats]
    );

    const handleToggleChange = useCallback(toggleName => {
        setToggles(prevToggles => ({
            ...prevToggles,
            [toggleName]: !prevToggles[toggleName],
        }));
    }, []);

    const handleRunButtonClick = async () => {
        try {
            setLoading(true);
            await requestRetryImportJob(
                importJobId,
                toggles.shouldReCrawl,
                toggles.shouldReImport,
                toggles.shouldReImportAll,
                toggles.force
            );
            notifications.pushSuccess('Successfully requeued import job failures');
            setRetryImportModalOpen(false);
        } catch (error) {
            showToast(error);
        } finally {
            setLoading(false);
        }
    };

    const onRetryImportModalOpen = useCallback(() => {
        setRetryImportModalOpen(true);
    }, [setRetryImportModalOpen]);

    const onRetryImportModalClose = useCallback(() => {
        setRetryImportModalOpen(false);
    }, [setRetryImportModalOpen]);

    const refreshReportOem = oems.find(oem => importJob && importJob.oemId === oem.oemId);
    const refreshReportDate = importJob ? FormatDateToHumanReadable(importJob.updateDate ?? importJob.createDate) : '';
    const onSerializedImportJobBookCommandModalClose = () => {
        setShowImportJobBookCommandModal(false);
        setImportJobBookCommandId('');
    };

    return {
        headers,
        fetchMore,
        searchValue,
        handleSearchValueChange,
        handleSearchKeyDown,
        handleSearchBlur,
        handlers,
        data,
        loading,
        totalBooks,
        importJob,
        importJobId,
        refreshReportOem,
        refreshReportDate,
        confirmationRef,
        importFilter,
        showImportJobBookCommandModal,
        onSerializedImportJobBookCommandModalClose,
        importJobBookCommandId,
        handleImportFilterChange,
        toggles,
        retryImportModalOpen,
        setRetryImportModalOpen,
        onRetryImportModalOpen,
        onRetryImportModalClose,
        handleToggleChange,
        handleRunButtonClick,
        canRunRetryImportJob,
        hasAccess,
        handleForceCompleteButtonClick,
    };
};

export default useImportJobDetails;
