import { Route, Routes } from 'react-router-dom';
import Oems from 'components/Oem/Oems';
import ToolSelection from 'components/locations/Home/ToolSelection';
import AuthorizedElement from 'routes/AuthorizedElement';
import BooksListTagger from 'components/BookList/BooksListTagger';
import BulkEdit from './BulkEdit';
import TaggerList from './TaggerList/TaggerList';
import TaggerTool from './TaggerTool/TaggerTool';
import TaggerLink from './TaggerLink/TaggerLink';
import FlaggerTool from './FlaggerTool/FlaggerTool';
import FlagDispositionRules from './FlagDispositionRules/FlagDispositionRules';
import TaggerContextProvider from 'contexts/TaggerContextProvider';
import fetchCounts from 'helpers/FetchCountsHelper';

const TaggingProcessRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    path={'/'}
                    element={
                        <ToolSelection
                            tools={[
                                {
                                    path: '/tagging-process/tagger',
                                    text: 'Tagger',
                                    icon: 'fill-drip',
                                    tileDescription:
                                        'Tool for converting flags to tags or adding a new tags to procedure',
                                    access: 'tool.taggingProcess.tagger',
                                },
                                {
                                    path: '/tagging-process/flagger',
                                    text: 'Flagger',
                                    icon: 'flag',
                                    tileDescription: 'Tool for running and managing terms used by flagger',
                                    access: 'tool.taggingProcess.flagger',
                                },
                                {
                                    path: '/tagging-process/flag-disposition-rules',
                                    text: 'Flag Disposition Rules',
                                    icon: 'snowplow',
                                    tileDescription: 'Tool for creating and running flag disposition rules',
                                    access: 'tool.taggingProcess.flagDispositionRules',
                                },
                            ]}
                        />
                    }
                />
            </Routes>

            <TaggerContextProvider>
                <Routes>
                    <Route
                        path="/tagger"
                        element={
                            <AuthorizedElement resource={'tool.taggingProcess.tagger'}>
                                <Oems fetchCounts={fetchCounts} context="tagger" />
                            </AuthorizedElement>
                        }
                    />
                    <Route
                        path="/tagger/:oemId"
                        element={
                            <AuthorizedElement resource={'tool.taggingProcess.tagger'}>
                                <BooksListTagger />
                            </AuthorizedElement>
                        }
                    />
                    <Route
                        path="/tagger/:oemId/bulkedit"
                        element={
                            <AuthorizedElement resource={'tool.taggingProcess.tagger'}>
                                <BulkEdit />
                            </AuthorizedElement>
                        }
                    />
                    <Route
                        path="/tagger/:oemId/:bookId"
                        element={
                            <AuthorizedElement resource={'tool.taggingProcess.tagger'}>
                                <TaggerList />
                            </AuthorizedElement>
                        }
                    />
                    <Route
                        path="/tagger/:oemId/:bookId/tag/:procedureId"
                        element={
                            <AuthorizedElement resource={'tool.taggingProcess.tagger'}>
                                <TaggerTool />
                            </AuthorizedElement>
                        }
                    />
                    <Route
                        path="/tagger/:oemId/:bookId/link/:tagId"
                        element={
                            <AuthorizedElement resource={'tool.taggingProcess.tagger'}>
                                <TaggerLink />
                            </AuthorizedElement>
                        }
                    />
                </Routes>
            </TaggerContextProvider>

            <Routes>
                <Route
                    path="/flagger"
                    element={
                        <AuthorizedElement resource={'tool.taggingProcess.flagger'}>
                            <Oems />
                        </AuthorizedElement>
                    }
                />
                <Route
                    path="flagger/:oemId"
                    element={
                        <AuthorizedElement resource={'tool.taggingProcess.flagger'}>
                            <FlaggerTool />
                        </AuthorizedElement>
                    }
                />
            </Routes>

            <Routes>
                <Route
                    path="/flag-disposition-rules"
                    element={
                        <AuthorizedElement resource={'tool.taggingProcess.flagDispositionRules'}>
                            <Oems />
                        </AuthorizedElement>
                    }
                />
                <Route
                    path="/flag-disposition-rules/:oemId"
                    element={
                        <AuthorizedElement resource={'tool.taggingProcess.flagDispositionRules'}>
                            <FlagDispositionRules />
                        </AuthorizedElement>
                    }
                />
            </Routes>
        </>
    );
};

export default TaggingProcessRoutes;
