import React, { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { PrimaryButton } from 'oemiq-common';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import useOemService from 'hooks/OemModels/useOemService';
import useModal from 'hooks/useModal';
import useProcedures from 'components/OemProceduresTable/items/useProcedures';
import useColumnSettings from './useColumnSettings';
import useSorting from './useSorting';
import ColumnSettings from 'components/locations/MappingProcess/MapperList/Actions/ColumnSettings/ColumnSettings';
import BuildMappingRuleModal from './BuildMappingRuleModal/BuildMappingRuleModal';
import OemProceduresTable from 'components/OemProceduresTable/OemProceduresTable';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import TableFilters from 'components/Shared/TableFilters/TableFilters';
import buildFiltersConfiguration from './buildFiltersConfiguration';
import ProcedureFilterCheckboxes from './MappingRuleFilters/ProcedureFilterCheckboxes';
import { isFilterRulable } from 'components/Shared/TableFilters/operators';
import { createFiltersFromSearchParams } from 'components/Shared/TableFilters/tableFilterHelpers';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import ViewProcedureMappingHistoryModal from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';
import { useSearchParams } from 'react-router-dom';
import SmallSpinner from 'components/SmallSpinner';
import useBulkSelect from '../../../../hooks/useBulkSelect';
import { SingleGroupTypeModal } from 'components/locations/MappingProcess/MapperList/MapperTable/GroupTypeModal/GroupTypeModal';
import { MapperListContext } from 'contexts/MapperListContext';
import BulkMoveModal from '../MapperList/MapperTable/BulkMoveModal/BulkMoveModal';
import GroupTypeModal from 'components/locations/MappingProcess/MapperList/MapperTable/GroupTypeModal/GroupTypeModal';
import BulkTaskHistoryModal from '../MapperList/BulkTaskHistoryModal';
import { ESProcedure } from './ESProcedure';
import { buildESFiltersFromCheckboxes, buildSQLFiltersFromCheckboxes } from './MappingRuleFilters/ApplyFilters';
import { LoadingContext } from 'components/Layout';
import { match } from 'ts-pattern';
import { ToggleButton, ButtonGroup } from 'react-bootstrap';

export enum DataSource {
    SQL = 'sql',
    ES = 'es',
}

export const buildFilters = (dataFilters, filterCheckboxes, dataSource: DataSource) => {
    return match(dataSource)
        .with(DataSource.ES, () => {
            const checkboxFilters = buildESFiltersFromCheckboxes(filterCheckboxes);
            const mainFilters = dataFilters.map(f => f.esUrlFilter).filter(f => f);

            return [...checkboxFilters, ...mainFilters];
        })
        .with(DataSource.SQL, () => {
            const filterExprs = buildSQLFiltersFromCheckboxes(filterCheckboxes);
            filterExprs.push(...dataFilters.map(f => f.sqlUrlFilter).filter(f => f));

            return filterExprs;
        })
        .exhaustive();
};

const ES_BULK_LIMIT = 10000;

const defaultFilterCheckboxes = {
    filterNoGroups: false,
    filterNoType: false,
    filterRefreshedPending: false,
    filterOnlyHotSheet: false,
    filterRemovedRemovalPending: false,
};

const MappingProceduresTool = () => {
    const { notifications } = useContext(NotificationsContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { hasAccess } = useContext(AccessControlContext);

    const { oemId } = useParams();
    const routeParams = useLocation();
    const [searchParams] = useSearchParams();
    const searchParamsObject = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
    const navigate = useNavigate();

    const [dataSource, setDataSource] = useState<DataSource>(DataSource.ES);

    const { oemMetadata, oemEngineService, oemService } = useOemService(oemId, dataSource);

    const [errorMessage, setErrorMessage] = useState(null);
    const filtersConfiguration = buildFiltersConfiguration(oemMetadata);
    const [newFilters, setNewFilters] = useState(
        routeParams.search ? createFiltersFromSearchParams(searchParamsObject, filtersConfiguration) : []
    );

    const [bulkOperationsProcedureIds, setBulkOperationsProcedureIds] = useState([]);
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    if (!oemMetadata) {
        !errorMessage && setErrorMessage('Oem selected does not currently support mapping engine functionality');
    }

    const handleDataSourceChangeToSQL = useCallback(() => {
        const ind = newFilters.findIndex(f => f.id === 'ProcedureHTML');
        if (ind !== -1) {
            setNewFilters(current => [...current.filter((f, i) => i !== ind)]);
        }
        setDataSource(DataSource.SQL);
    }, [newFilters]);

    const handleDataSourceChangeToES = useCallback(() => {
        setDataSource(DataSource.ES);
    }, []);

    const handleCreateMappingRule = useCallback(
        async rule => {
            try {
                await oemEngineService.createRule(rule);
                notifications.pushSuccess('New mapping rule created');
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, oemEngineService]
    );

    const { orderBy, handleSorting } = useSorting();

    const [filterCheckboxes, setFilterCheckboxes] = useState(defaultFilterCheckboxes);
    const filters: string[] = useMemo(
        () => buildFilters(newFilters, filterCheckboxes, dataSource),
        [dataSource, filterCheckboxes, newFilters]
    );

    const {
        data,
        hasMoreData,
        loading: isLoadingProcedures,
        totalCount,
        loadMoreCallback,
        refreshProcedures,
        handleReloadButton,
        updateOemIqTypeForProcedureIds,
        updateProcedures,
        setNewGroupListToProcedureByProcedureId,
        getProcedureIds,
    } = useProcedures(filters, orderBy, oemId, filterCheckboxes.filterRemovedRemovalPending, oemMetadata, dataSource);

    const { columnSettings, handleSaveColumnSettings, setShowColumnSettings, showColumnSettings } = useColumnSettings(
        'MappingEngineTool',
        oemMetadata.properties
    );

    const canCreateRule = () => {
        const filters = newFilters.filter(f => f.property);
        const ruleableFilters = filters.filter(isFilterRulable);
        const uniqueFilterCount = new Set(filters.map(f => f.property)).size;
        return (
            filters.length > 0 &&
            !filterCheckboxes.filterRefreshedPending &&
            !filterCheckboxes.filterNoGroups &&
            !filterCheckboxes.filterNoType &&
            ruleableFilters.length === filters.length &&
            ruleableFilters.length === uniqueFilterCount
        );
    };

    const navigateToRules = useCallback(() => {
        const filters = newFilters
            .filter(f => f.ruleable)
            .map(f => ({
                id: oemMetadata.properties.find(p => p.id === f.id).rulePropertyName,
                operator: f.operator.value,
                value: f.value,
            }));

        navigate(`/mapping-process/rules-runner/${oemId}`, { state: { filters } });
    }, [newFilters, navigate, oemId, oemMetadata.properties]);

    const [isCreateMappingRuleModalOpen, setCreateMappingRuleModalOpen] = useState<boolean>(false);
    const [modalProcedure, setModalProcedure] = useState<ESProcedure | undefined>();
    const [historyModalProcedure, setHistoryModalProcedure] = useState(null);

    const isRemovalPending = modalProcedure?.stageArea.isDeleted && !modalProcedure.isDeleted;
    const isRemoved = modalProcedure?.stageArea.isDeleted && modalProcedure.isDeleted;

    const {
        isModalOpen: isSingleGroupTypeModalOpen,
        openModal: openSingleGroupTypeModal,
        closeModal: closeSingleGroupTypeModal,
    } = useModal();

    const {
        isModalOpen: isBulkMoveModalOpen,
        openModal: openBulkMoveModal,
        closeModal: closeBulkMoveModal,
    } = useModal();

    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();

    const [groupTypeModalBulkType, setGroupTypeModalBulkType] = useState(null);

    const handleOpenBulkAssignModal = useCallback(() => {
        setGroupTypeModalBulkType('Assign');
        openGroupTypeModal();
    }, [openGroupTypeModal, setGroupTypeModalBulkType]);

    const handleOpenBulkRemoveModal = useCallback(() => {
        setGroupTypeModalBulkType('Remove');
        openGroupTypeModal();
    }, [openGroupTypeModal, setGroupTypeModalBulkType]);

    const [isBulkActionHistoryModalOpen, setIsBulkActionHistoryModalOpen] = useState(false);

    const handleBulkTaskHistoryButtonClick = useCallback(() => {
        setIsBulkActionHistoryModalOpen(true);
    }, []);

    const handleBulkTaskHistoryToggle = useCallback(() => {
        setIsBulkActionHistoryModalOpen(prev => !prev);
    }, []);

    const contextValue = useMemo(() => {
        return {
            refreshProcedures,
            updateProcedures,
            updateOemIqTypeForProcedureIds,
            setNewGroupListToProcedureByProcedureId,
            toggleBulkTaskHistoryModal: handleBulkTaskHistoryToggle,
        };
    }, [
        refreshProcedures,
        updateProcedures,
        updateOemIqTypeForProcedureIds,
        setNewGroupListToProcedureByProcedureId,
        handleBulkTaskHistoryToggle,
    ]);

    const { selected, isAll, resetSelected, handleSelectAll, handleSelectOne } = useBulkSelect(
        totalCount.value,
        'procedureId',
        true
    );

    useEffect(() => {
        const fetchProcedures = async () => {
            try {
                incrementLoading();
                const procedureIds = await getProcedureIds(ES_BULK_LIMIT, 0);
                setBulkOperationsProcedureIds(procedureIds);
            } catch (error) {
                setErrorMessage(error);
            } finally {
                decrementLoading();
            }
        };

        if (dataSource === DataSource.ES && isAll == true) {
            if (totalCount.value > ES_BULK_LIMIT) {
                notifications.pushSuccess(
                    'The requested operation will only be performed on 10k procedures. Please repeat the operation for the remaining procedures'
                );
            }

            fetchProcedures();
        }
    }, [isAll, getProcedureIds, incrementLoading, decrementLoading, totalCount.value, notifications, dataSource]);

    return (
        <MapperListContext.Provider value={contextValue}>
            <div className="container-main pt-3 pe-3 ps-3 d-flex flex-column">
                <div className="d-flex">
                    <h2 className="flex-grow-1">Procedures</h2>
                    <ButtonGroup className="me-3" aria-label="Switch Elastic-SQL">
                        <ToggleButton
                            value="esButton"
                            id="ESProcedureModeButton"
                            title="Elasticsearch"
                            className="py-2 px-4"
                            type="radio"
                            name="radio"
                            variant={dataSource === DataSource.ES ? 'primary' : 'outline-primary'}
                            checked={dataSource === DataSource.ES}
                            onChange={handleDataSourceChangeToES}>
                            Elasticsearch
                        </ToggleButton>
                        <ToggleButton
                            value="sqlButton"
                            id="SQLProcedureModeButton"
                            title="SQL"
                            className="py-2 px-4"
                            type="radio"
                            name="radio"
                            variant={dataSource === DataSource.SQL ? 'primary' : 'outline-primary'}
                            checked={dataSource === DataSource.SQL}
                            onChange={handleDataSourceChangeToSQL}>
                            SQL
                        </ToggleButton>
                    </ButtonGroup>
                    <PrimaryButton
                        id="bulk-task-history-button"
                        type="button"
                        className="me-3"
                        onClick={handleBulkTaskHistoryButtonClick}>
                        Bulk Task History
                    </PrimaryButton>
                    <PrimaryButton id="link-goto-view-rules" type="button" className="me-3" onClick={navigateToRules}>
                        View Rules <FontAwesomeIcon className="ms-1" icon="arrow-up-right-from-square" />
                    </PrimaryButton>
                    <PrimaryButton
                        id="btn-modal-create-mapping-rule"
                        className="me-3"
                        type="button"
                        disabled={!canCreateRule() || !hasAccess('rule.create')}
                        onClick={() => setCreateMappingRuleModalOpen(true)}>
                        Create New Mapping Rule
                    </PrimaryButton>
                    <PrimaryButton id="column-settings" type="button" onClick={() => setShowColumnSettings(true)}>
                        Column Settings
                    </PrimaryButton>
                </div>

                <div className="text-danger">{errorMessage && errorMessage}</div>

                <TableFilters
                    configuration={filtersConfiguration}
                    filters={newFilters}
                    setFilters={setNewFilters}
                    onReloadButtonClick={handleReloadButton}
                />

                <ProcedureFilterCheckboxes
                    filterCheckboxes={filterCheckboxes}
                    setFilterCheckboxes={setFilterCheckboxes}
                />

                {isSingleGroupTypeModalOpen && (
                    <SingleGroupTypeModal
                        isOpen={isSingleGroupTypeModalOpen}
                        procedure={modalProcedure}
                        closeGroupTypeModal={closeSingleGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    />
                )}

                <ViewProcedureModal
                    procedureId={modalProcedure?.procedureId || null}
                    resetProcedureId={() => setModalProcedure({ ...modalProcedure, procedureId: null })}
                    isRemoved={isRemovalPending || isRemoved}
                    headerButtons={
                        <div style={{ marginTop: -6, marginBottom: -5 }}>
                            <PrimaryButton
                                className="btn-sm"
                                id="map-procedure"
                                type="button"
                                disabled={isRemovalPending || isRemoved}
                                onClick={openSingleGroupTypeModal}>
                                Map Procedures
                            </PrimaryButton>
                        </div>
                    }
                />

                <ViewProcedureMappingHistoryModal
                    procedure={historyModalProcedure}
                    resetProcedureId={() => setHistoryModalProcedure(null)}
                />

                <OemProceduresTable
                    data={data}
                    hasMoreData={hasMoreData}
                    oemMetadata={oemMetadata}
                    columnSettings={columnSettings}
                    isLoading={isLoadingProcedures}
                    onSorting={handleSorting}
                    loadMoreCallback={loadMoreCallback}
                    onClickModalProcedure={setModalProcedure}
                    onclickHistoryModalProcedure={setHistoryModalProcedure}
                    refreshProcedures={refreshProcedures}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    isEditable={true}
                    selected={selected}
                    isAll={isAll}
                    handleSelectAll={handleSelectAll}
                    handleSelectOne={handleSelectOne}
                />

                <footer id="status-bar" className="m-1">
                    <div className="d-flex">
                        <div className="d-flex flex-grow-1 align-items-center">
                            <TotalCount
                                totalCount={totalCount}
                                current={data.length}
                                selectedCount={
                                    isAll
                                        ? dataSource === DataSource.ES
                                            ? bulkOperationsProcedureIds.length
                                            : totalCount.selectableCount
                                        : selected.length
                                }
                            />
                        </div>
                        <div className="flex-row-reverse">
                            {(selected.length >= 1 || isAll) && (
                                <div className="mt-3 mb-3">
                                    <span className="pe-2 pb-1">
                                        <PrimaryButton
                                            id="bulk-assign-procedures"
                                            type="button"
                                            disabled={selected.length <= 1 && !isAll}
                                            onClick={handleOpenBulkAssignModal}>
                                            Bulk Assign
                                        </PrimaryButton>
                                    </span>
                                    <span className="pe-2 pb-1">
                                        <PrimaryButton
                                            id="bulk-unassign-procedures"
                                            type="button"
                                            disabled={selected.length <= 1 && !isAll}
                                            onClick={handleOpenBulkRemoveModal}>
                                            Bulk Remove
                                        </PrimaryButton>
                                    </span>
                                    <span className="pe-2 pb-1">
                                        <PrimaryButton
                                            id="bulk-move-procedures"
                                            type="button"
                                            onClick={openBulkMoveModal}>
                                            Bulk Move
                                        </PrimaryButton>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </footer>

                {isGroupTypeModalOpen && (
                    <GroupTypeModal
                        isOpen={isGroupTypeModalOpen}
                        groupTypeModalProcedureIds={isAll ? bulkOperationsProcedureIds : selected}
                        selectedProceduresCount={isAll ? bulkOperationsProcedureIds.length : selected.length}
                        resetProcedureIds={closeGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                        procedures={isAll ? data : data.filter(p => selected.includes(p.procedureId))}
                        resetBulkSelection={resetSelected}
                        groupTypeModalBulkType={groupTypeModalBulkType}
                        setGroupTypeModalBulkType={setGroupTypeModalBulkType}
                        oemId={parseInt(oemId)}
                        oDataFilter={
                            isAll && dataSource === DataSource.SQL
                                ? oemService.buildSQLFilter(
                                      buildFilters(newFilters, filterCheckboxes, DataSource.SQL).join(' and ')
                                  )
                                : null
                        }
                    />
                )}

                {isBulkMoveModalOpen && (
                    <BulkMoveModal
                        isOpen={isBulkMoveModalOpen}
                        statusModalProcedureIds={isAll ? bulkOperationsProcedureIds : selected}
                        selectedProceduresCount={isAll ? bulkOperationsProcedureIds.length : selected.length}
                        resetProcedureIds={closeBulkMoveModal}
                        resetBulkSelection={resetSelected}
                        procedures={isAll ? data : data.filter(p => selected.includes(p.procedureId))}
                        oDataFilter={
                            isAll && dataSource === DataSource.SQL
                                ? oemService.buildSQLFilter(
                                      buildFilters(newFilters, filterCheckboxes, DataSource.SQL).join(' and ')
                                  )
                                : null
                        }
                    />
                )}

                {isBulkActionHistoryModalOpen && (
                    <BulkTaskHistoryModal
                        isOpen={isBulkActionHistoryModalOpen}
                        onToggle={handleBulkTaskHistoryToggle}
                    />
                )}

                <BuildMappingRuleModal
                    isModalOpen={isCreateMappingRuleModalOpen}
                    setModalOpen={setCreateMappingRuleModalOpen}
                    filters={newFilters.filter(f => f.property)}
                    mappingDefinitions={mappingDefinitions}
                    proceduresCount={totalCount}
                    oemMetadata={oemMetadata}
                    onCreateMappingRule={handleCreateMappingRule}
                />

                <ColumnSettings
                    showColumnSettings={showColumnSettings}
                    setShowColumnSettings={setShowColumnSettings}
                    hideDynamicKeyList={[]}
                    dynamicProcedureKeys={columnSettings}
                    onSaveColumnSettings={handleSaveColumnSettings}
                />
            </div>
        </MapperListContext.Provider>
    );
};

type TotalCountProps = {
    totalCount: { error: boolean; value: number; loadingCount: boolean; selectableCount: number };
    current: number;
    selectedCount: number;
};

const TotalCount = ({ totalCount, current, selectedCount }: TotalCountProps) => {
    if (totalCount.loadingCount) {
        return <SmallSpinner />;
    }

    if (totalCount.error) {
        return <span className="total-count-error">Unable to load total count (loaded {current} procedures)</span>;
    }

    return (
        <>
            {current} procedures of {totalCount.value} ({selectedCount} selected)
        </>
    );
};

export default MappingProceduresTool;
