import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import ToastProvider from 'components/ToastProvider';
import { ToastType } from 'components/ToastProvider/ToastType';
import Toast from 'components/ToastProvider/Toast';
import { reactPlugin } from '../src/api/config/AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import useFontAwesomeIcons from 'hooks/useFontAwesomeIcons';
import AppRoutes from './routes/AppRoutes';
import PerformanceCheckProvider from 'contexts/PerformanceCheckContextProvider';
import Authenticated from 'auth/Authenticated';
import AccessControl from 'components/Shared/AccessControl/AccessControl';
import { ApplicationPaths } from 'auth/AuthenticationConstants';
import { Login, LoginCallback, LoginFailed } from 'auth/Login';
import GoogleAnalytics from 'ga/GoogleAnalytics';

const App = () => {
    // eslint-disable-next-line no-empty-pattern
    const {} = useFontAwesomeIcons(); //Imports Font Awesome Icons to avoid huge import statement in App.jsx
    const [open, setOpen] = useState(false);

    return (
        <AppInsightsErrorBoundary
            onError={() => {
                setOpen(true);
                return (
                    <Toast
                        isOpen={open}
                        header={'Error'}
                        type={ToastType.ERROR}
                        message={'An unexpected error has occurred! Please refresh the page.'}
                        toggle={() => setOpen(false)}
                    />
                );
            }}
            appInsights={reactPlugin}>
            <ToastProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path={ApplicationPaths.Login} element={<Login />} />
                        <Route path={ApplicationPaths.LoginFailed} element={<LoginFailed />} />
                        <Route path={ApplicationPaths.LoginCallback} element={<LoginCallback />} />
                        <Route
                            path="*"
                            element={
                                <Authenticated>
                                    <AccessControl>
                                        <Layout>
                                            <PerformanceCheckProvider>
                                                <AppRoutes />
                                            </PerformanceCheckProvider>
                                        </Layout>
                                        <GoogleAnalytics />
                                    </AccessControl>
                                </Authenticated>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </ToastProvider>
        </AppInsightsErrorBoundary>
    );
};

export default App;
