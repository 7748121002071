import { useParams } from 'react-router-dom';
import Breadcrumb from './items/Breadcrumb';
import OemNameBreadcrumb from 'components/Navigation/items/OemNameBreadcrumb';
import kebab2Camel from './utils/kebab2Camel';
import BookNameBreadcrumb from './items/BookNameBreadcrumb';

const Tools = {
    mapper: 'Mapper',
    rulesRunner: 'Rules Runner',
    rulesCreator: 'Rules Creator',
    procedures: 'Procedures',
    positionStatements: 'Position Statements',
    mappingTraining: 'Mapping Training',
};

const MappingProcessNavigation = () => {
    const { mappingTool, oemId, bookId } = useParams();

    return (
        <ol className="breadcrumb">
            <Breadcrumb isShown={true} isCurrent={!mappingTool} link={'/mapping-process'} text={'Mapping Process'} />
            {mappingTool && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/mapping-process/${mappingTool}`}
                    text={Tools[kebab2Camel(mappingTool)]}
                />
            )}
            {mappingTool && oemId && (
                <OemNameBreadcrumb
                    oemId={oemId}
                    isCurrent={!bookId}
                    link={`/mapping-process/${mappingTool}/${oemId}`}
                    withImportJob={mappingTool === 'mapper'}
                />
            )}
            {mappingTool && oemId && bookId && (
                <BookNameBreadcrumb
                    oemId={oemId}
                    bookId={bookId}
                    baseUrl={`/mapping-process/${mappingTool}`}
                    isCurrent={true}
                />
            )}
        </ol>
    );
};

export default MappingProcessNavigation;
