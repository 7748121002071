import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    requestGenesisBulkSetShouldVehicleBeRun,
    requestGenesisVehicleScrapeManagerOdata,
} from 'api/RepairProcedureApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { GenesisVehicle, IngestionButton } from '../types';

const useGenesisVehicles = notifications => {
    const [vehicles, setVehicles] = useState<GenesisVehicle[]>([]);
    const [filters, setFilters] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    const page = useRef(0);
    const pageSize = 100;

    const loadVehicles = useCallback(async () => {
        if (!hasMore) return;
        try {
            setLoading(true);
            incrementLoading();

            const vehicles = await getVehicles(page.current, pageSize, filters);
            page.current++;

            if (vehicles.data.length < pageSize) setHasMore(false);

            if (vehicles.data) {
                setVehicles(prevVehicles => {
                    const newVehicles = vehicles.data.filter(
                        veh => !prevVehicles.some(prevVehicle => prevVehicle.id === veh.id)
                    );
                    return [...prevVehicles, ...newVehicles];
                });
            }
        } catch (e) {
            notifications.pushExceptionDanger(e);
        } finally {
            setLoading(false);
            decrementLoading();
        }
    }, [incrementLoading, filters, setVehicles, notifications, decrementLoading, hasMore]);

    useEffect(() => {
        const getInitialVehicles = async () => {
            try {
                setLoading(true);
                incrementLoading();

                page.current = 0;

                const vehicles = await getVehicles(page.current, pageSize, filters);

                if (vehicles.data.length < pageSize) {
                    setHasMore(false);
                }

                if (vehicles.error) {
                    showToast(vehicles.error);
                }

                setVehicles(vehicles.data);
                page.current++;
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                setLoading(false);
                decrementLoading();
            }
        };
        getInitialVehicles();
    }, [decrementLoading, filters, incrementLoading, notifications, setVehicles, showToast]);

    const turnGenesisVehiclesOn = useCallback(
        async (id: number) => {
            try {
                const ids = [];
                ids.push(id);
                await requestGenesisBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, setVehicles]
    );

    const turnGenesisVehiclesOff = useCallback(
        async (id: number) => {
            try {
                const ids = [];
                ids.push(id);
                await requestGenesisBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, setVehicles]
    );

    const bulkTurnGenesisVehiclesOn = useCallback(
        async (ids: number[]) => {
            try {
                await requestGenesisBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, setVehicles]
    );

    const bulkTurnGenesisVehiclesOff = useCallback(
        async (ids: number[]) => {
            try {
                await requestGenesisBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, setVehicles]
    );

    const onSearch = (filter: string, radioValue: IngestionButton) => {
        const yearFilter = /^\d+$/.test(filter) ? `year eq ${filter}` : '';
        const modelNameFilter = `contains(modelName,'${filter}')`;
        const notesFilter = `contains(notes,'${filter}')`;

        const processFilter =
            radioValue === IngestionButton.NewButton
                ? 'and (hasBeenReviewed eq false)'
                : radioValue === IngestionButton.OnButton
                ? 'and (hasBeenReviewed eq true and shouldBeRun eq true)'
                : radioValue === IngestionButton.OffButton
                ? 'and (hasBeenReviewed eq true and shouldBeRun eq false)'
                : '';

        const parsedFilters = `isDeleted eq false and (${
            yearFilter && `${yearFilter} or`
        } ${modelNameFilter} or ${notesFilter}) ${processFilter}`;
        setFilters(parsedFilters);
    };

    return {
        vehicles,
        isLoading: loading,
        loadMoreCallback: loadVehicles,
        onSearch,
        turnGenesisVehiclesOn,
        turnGenesisVehiclesOff,
        bulkTurnGenesisVehiclesOn,
        bulkTurnGenesisVehiclesOff,
        hasMore,
    };
};

const getVehicles = async (page: number, pageSize: number, filter: string) => {
    try {
        const vehicles = await requestGenesisVehicleScrapeManagerOdata({
            top: pageSize,
            skip: pageSize * page,
            orderBy: 'modelName, modelCode, Year desc',
            filter: filter ? filter : 'isDeleted eq false',
        });
        return { data: vehicles.value, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

export default useGenesisVehicles;
