import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LargeCheckbox, PrimaryButton } from 'oemiq-common';
import TypeDropdownButton from './TypeDropdownButton';
import GroupColumn from './GroupColumn/GroupColumn';
import { MapperListContext } from 'contexts/MapperListContext';
import { SingleGroupTypeModal } from '../GroupTypeModal/GroupTypeModal';
import useModal from 'hooks/useModal';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import ViewProcedureMappingHistoryModal from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';

const MapperTableItem = ({
    procedure,
    isSelected,
    handleBulkSelection,
    setNewGroupListToProcedureByProcedureId,
    dynamicProcedureKeys,
    showIsDeletedFlags,
}) => {
    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();
    const {
        isModalOpen: isViewProcedureModalOpen,
        openModal: openViewProcedureModal,
        closeModal: closeViewProcedureModal,
    } = useModal();
    const {
        isModalOpen: isProcedureHistoryModalOpen,
        openModal: openProcedureHistoryModal,
        closeModal: closeProcedureHistoryModal,
    } = useModal();

    const { hideDynamicKeyList } = useContext(MapperListContext);
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    return (
        <tr>
            <td>
                <div>
                    <LargeCheckbox
                        id={`bulk-select-${procedure.procedureId}`}
                        label=""
                        className="large-checkbox-check mb-0"
                        checked={isSelected}
                        onChange={() => handleBulkSelection(procedure.procedureId)}
                        readOnly={false}
                    />
                </div>
            </td>
            <td>
                <div className="d-flex justify-content-center">
                    <button
                        id={`view-procedure-${procedure.procedureId}`}
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={openViewProcedureModal}>
                        <FontAwesomeIcon icon="eye" />
                    </button>
                    <button
                        type="button"
                        data-testid="procedure-history-button"
                        className="procedure-history-button btn btn-link ms-1"
                        onClick={openProcedureHistoryModal}>
                        <FontAwesomeIcon icon="history" />
                    </button>
                </div>
            </td>
            <td>{procedure.procedureId}</td>
            <td>
                <GroupColumn
                    groups={mappingDefinitions.groups}
                    procedure={procedure}
                    setGroupTypeModalProcedureIds={openGroupTypeModal}
                />
            </td>
            <td>
                <TypeDropdownButton
                    procedure={procedure}
                    mappingDefinitions={mappingDefinitions}
                    setGroupTypeModalProcedureIds={openGroupTypeModal}
                />
            </td>
            {showIsDeletedFlags && (
                <>
                    <td>{String(procedure.stageArea.isDeleted)}</td>
                    <td>{String(procedure.isDeleted)}</td>
                </>
            )}
            {dynamicProcedureKeys.map(d => {
                if (!d.isHidden && !hideDynamicKeyList.includes(d.text)) {
                    return (
                        <td
                            key={`${procedure.procedureId}-${d.text}`}
                            className="overflow-hidden"
                            style={{
                                textOverflow: 'ellipsis',
                                maxWidth: '1px',
                                minWidth: '1px',
                            }}>
                            {procedure[d.text] == null || Array.isArray(procedure[d.text])
                                ? ''
                                : String(procedure[d.text])}
                        </td>
                    );
                }
                return null;
            })}
            {isGroupTypeModalOpen && (
                <SingleGroupTypeModal
                    isOpen={isGroupTypeModalOpen}
                    procedure={procedure}
                    closeGroupTypeModal={closeGroupTypeModal}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                />
            )}
            {isViewProcedureModalOpen && (
                <ViewProcedureModal
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    procedureId={procedure.procedureId}
                    resetProcedureId={closeViewProcedureModal}
                    isRemoved={procedure.stageArea.isDeleted}
                    defaultCompareVersions={true}
                    headerButtons={
                        <div style={{ marginTop: -6, marginBottom: -5 }}>
                            <PrimaryButton
                                className="btn-sm"
                                id="map-procedure"
                                type="button"
                                onClick={openGroupTypeModal}>
                                Map Procedures
                            </PrimaryButton>
                        </div>
                    }
                />
            )}
            {isProcedureHistoryModalOpen && (
                <ViewProcedureMappingHistoryModal procedure={procedure} resetProcedureId={closeProcedureHistoryModal} />
            )}
        </tr>
    );
};

export default React.memo(MapperTableItem);
