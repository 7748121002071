import { Route, Routes } from 'react-router-dom';
import Oems from 'components/Oem/Oems';
import ToolSelection from 'components/locations/Home/ToolSelection';
import AuthorizedElement from 'routes/AuthorizedElement';
import BooksListMapper from 'components/BookList/BooksListMapper';
import MapperList from 'components/locations/MappingProcess/MapperList/MapperList';
import RulesRunnerTool from 'components/locations/MappingProcess/RulesRunner/RulesRunnerTool';
import MappingProceduresTool from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';
import PositionStatementList from 'components/locations/MappingProcess/PositionStatements/PositionStatementList';
import MappingTrainingTool from 'components/locations/MappingProcess/MappingTraining/MappingTrainingTool';
import RulesCreatorTool from 'components/locations/MappingProcess/RulesCreator/RulesCreatorTool';
import RedirectOemRoute from 'components/Navigation/utils/RedirectOemRoute';

const MappingProcessRoutes = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <ToolSelection
                        tools={[
                            {
                                path: '/mapping-process/mapper',
                                text: 'Mapper',
                                icon: 'map',
                                tileDescription: 'Tool for mapping groups and a type to procedures',
                                access: 'tool.mappingProcess.mapper',
                            },
                            {
                                path: '/mapping-process/rules-creator',
                                text: 'Mapping Rules Creator',
                                icon: 'clipboard',
                                tileDescription: 'Tool for creating mapping rules',
                                access: 'tool.mappingProcess.rulesCreator',
                            },
                            {
                                path: '/mapping-process/rules-runner',
                                text: 'Mapping Rules Runner',
                                icon: 'chevron-circle-right',
                                tileDescription: 'Tool for running mapping rules',
                                access: 'tool.mappingProcess.rulesRunner',
                            },
                            {
                                path: '/mapping-process/procedures',
                                text: 'Procedures',
                                icon: 'car-side',
                                tileDescription: 'Tool for filtering repair procedures',
                                access: 'tool.mappingProcess.procedures',
                            },
                            {
                                path: '/mapping-process/position-statements',
                                text: 'Position Statements',
                                icon: 'file-pdf',
                                tileDescription: 'Tool for uploading new Position Statements.',
                                access: 'tool.mappingProcess.positionStatements',
                            },
                            {
                                path: '/mapping-process/mapping-training',
                                text: 'Mapping Training',
                                icon: 'award',
                                tileDescription:
                                    'Tool for creating new test manuals for data to training on mapping without effecting live systems',
                                access: 'tool.mappingProcess.mappingTraining',
                            },
                        ]}
                    />
                }
            />

            <Route
                path="/mapper"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.mapper'}>
                        <Oems />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/mapper/:oemId"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.mapper'}>
                        <BooksListMapper procedureTools={false} />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/mapper/:oemId/:bookId"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.mapper'}>
                        <MapperList />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/rules-creator"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.rulesCreator'}>
                        <Oems />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/rules-creator/:oemId"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.rulesCreator'}>
                        <RulesCreatorTool />
                    </AuthorizedElement>
                }
            />
            {RedirectOemRoute('/rules-creator/', '/mapping-process/rules-creator/')}
            <Route
                path="/rules-runner"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.rulesRunner'}>
                        <Oems />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/rules-runner/:oemId"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.rulesRunner'}>
                        <RulesRunnerTool />
                    </AuthorizedElement>
                }
            />
            {RedirectOemRoute('/rules-runner/', '/mapping-process/rules-runner/')}
            <Route
                path="/procedures"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.procedures'}>
                        <Oems mode={{ procedureTools: true }} />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/procedures/:oemId"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.procedures'}>
                        <MappingProceduresTool />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/procedures/:oemId/book-list"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.procedures'}>
                        <BooksListMapper procedureTools={true} />
                    </AuthorizedElement>
                }
            />
            {RedirectOemRoute('/procedures/', '/mapping-process/procedures/')}
            <Route
                path="/position-statements"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.positionStatements'}>
                        <Oems mode={{ hasProcedureVehicles: true }} />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/position-statements/:oemId"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.positionStatements'}>
                        <PositionStatementList />
                    </AuthorizedElement>
                }
            />
            <Route
                path={'/mapping-training'}
                element={
                    <AuthorizedElement resource={'tool.mappingProcess.mappingTraining'}>
                        <MappingTrainingTool />
                    </AuthorizedElement>
                }
            />
        </Routes>
    );
};

export default MappingProcessRoutes;
