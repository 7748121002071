import { Text } from './Text';
import { operatorsList } from '../operators';

export default {
    component: Text,
    format: v => `'${v}'`,
    formatES: v => `${v}`,
    operators: [
        operatorsList.containsBookName,
        operatorsList.notContainsBookName,
        operatorsList.eqBookName,
        operatorsList.neBookName,
    ],
    defaultValue: 0,
    allowFalse: false,
    allowInstances: 1,
};
