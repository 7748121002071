import React, { useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

import Select from 'react-select';
import { operatorsList, ManuallyMapped } from '../operators';
import { isNil } from 'lodash';

const components = {
    DropdownIndicator: null,
};

const createOption = label => ({
    label,
    value: label,
});
const manuallyMappedOptions = [
    { value: ManuallyMapped.INCLUDE, label: 'Include manually mapped' },
    { value: ManuallyMapped.EXCLUDE, label: 'Exclude manually mapped' },
];

const validateValue = value => /^\d+$/.test(value);

const MappingRuleId = ({ filter, setFilterValue }) => {
    const { rulesIds, manuallyMapped } = filter.value;

    const [inputValue, setInputValue] = useState('');
    const handleChangeValue = filterValue => {
        const valueList = filterValue.rulesIds.map(v => v.value);
        if (filterValue.manuallyMapped === ManuallyMapped.INCLUDE) {
            valueList.unshift('M');
        } else if (filterValue.manuallyMapped === ManuallyMapped.EXCLUDE) {
            valueList.unshift('–M');
        }

        return setFilterValue({ value: filterValue, valueList });
    };
    const handleChangeRulesIds = rulesIds => handleChangeValue({ rulesIds, manuallyMapped });
    const handleChangeManuallyMapped = manuallyMapped => {
        return handleChangeValue({ rulesIds, manuallyMapped });
    };
    const invalidValues = useMemo(
        () =>
            rulesIds
                .map(v => v.value)
                .concat(inputValue ? [inputValue] : [])
                .filter(v => !validateValue(v)),
        [rulesIds, inputValue]
    );

    const handleKeyDown = event => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                handleChangeRulesIds([...(rulesIds ?? []), createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    const handleBlur = () => {
        if (!inputValue) return;
        handleChangeRulesIds([...(rulesIds ?? []), createOption(inputValue)]);
        setInputValue('');
    };

    return (
        <>
            <div className={`d-flex mb2${invalidValues.length > 0 ? ' is-invalid' : ''}`} style={{ gap: '0.5rem' }}>
                <Select
                    className="form-select flex-basis-50"
                    isClearable
                    placeholder="Manually mapped"
                    value={manuallyMappedOptions.find(f => f.value == manuallyMapped)}
                    onChange={v => handleChangeManuallyMapped(v?.value ?? null)}
                    options={manuallyMappedOptions}
                />
                <CreatableSelect
                    className="flex-basis-50"
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => handleChangeRulesIds(newValue)}
                    onInputChange={newValue => setInputValue(newValue)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    placeholder="Type Mapping Rule Id. Press Enter or Tab to commit"
                    value={rulesIds}
                />
            </div>
            <div className="invalid-feedback">Value {invalidValues.join(', ')} is invalid</div>
        </>
    );
};

export default {
    component: MappingRuleId,
    format: v => v,
    formatES: v => v,
    operators: [operatorsList.mappedBy],
    defaultValue: { rulesIds: [], manuallyMapped: null },
    allowFalse: false,
    allowInstances: 10,
    validator: v =>
        (!isNil(v.manuallyMapped) || v.rulesIds.length > 0) && v.rulesIds.map(v => v.value).every(validateValue),
};
