import { useState, useEffect, useCallback, useContext, useRef, useMemo } from 'react';
import { LoadingContext } from 'components/Layout';
import {
    requestVehicleByOem,
    requestLastVehiclePublishHistory,
    requestVehiclePublishException,
    requestVehiclePublishExceptionCategories,
    requestUpdateVehiclePublishException,
    requestAddVehiclePublishException,
} from 'api/vehicleInfo';
import { getVehicleStats } from 'api/RepairProcedureApi';
import { requestMappingStatistics, requestBookTaggerStats, requestBooksByVehicle } from 'api/RepairProcedureApi';
import { VehiclePublishExceptionTrackerFieldEnum } from 'helpers/VehicleProcessHelper';
import { isEmpty, isEqual } from 'lodash';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';

const getBooksForVehicle = async vehicle => {
    const books = await requestBooksByVehicle(
        vehicle.year.yearId,
        vehicle.oem.oemId,
        vehicle.model.modelId,
        vehicle.isTrimLevelFilteringEnabled ? vehicle.trim.trimId : null
    );
    return books.map(b => {
        return {
            ...b,
            id: `${b.bookId}${vehicle.vehicleId}`,
            vehicleRefId: vehicle.vehicleId,
            isVehicle: false,
            isLoaded: false,
            label: `${b.bookName}`,
        };
    });
};

const getVehicles = async (oemId, modelId) => {
    const vehicles = await requestVehicleByOem(oemId, modelId);

    return vehicles
        .map(v => {
            return {
                ...v,
                id: `${v.vehicleId}${modelId}`,
                isVehicle: true,
                isLoaded: false,
                published: v.isActive,
                label: `${v.year.yearValue} ${v.model.modelName} - ${v.trim.trimName || 'Base'}`,
                datePublished: {
                    vehicle: v,
                },
                trim: { ...v.trim, trimName: v.trim.trimName || 'Base' },
            };
        })
        .sort(function (a, b) {
            return b.year.yearId - a.year.yearId || a.trim.trimName.localeCompare(b.trim.trimName);
        });
};

const useVehicles = (oemId, modelId, showBooks, notifications) => {
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const [vehicles, setVehicles] = useState([]);
    const [books, setBooks] = useState([]);
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const vehiclesStats = useRef([]);
    const [editMode, setEditMode] = useState(true);
    const { hasRole } = useContext(AccessControlContext);

    const loadVehicleStats = useCallback(async vehicle => {
        let vehicleStats = vehiclesStats.current.find(
            v =>
                v.oemId === vehicle.oem.oemId && v.yearId === vehicle.year.yearId && v.modelId === vehicle.model.modelId
        );
        if (!vehicleStats) {
            vehicleStats = await getVehicleStats(vehicle.oem.oemId, vehicle.year.yearId, vehicle.model.modelId);
            vehiclesStats.current.push({
                ...vehicleStats,
                oemId: vehicle.oem.oemId,
                yearId: vehicle.year.yearId,
                modelId: vehicle.model.modelId,
            });
        }

        setData(currentVehicles => {
            return currentVehicles.map(v => {
                if (
                    v.oem.oemId === vehicle.oem.oemId &&
                    v.year.yearId === vehicle.year.yearId &&
                    v.model.modelId === vehicle.model.modelId
                ) {
                    return {
                        ...v,
                        isLoaded: true,
                        mappingStatus: vehicleStats.mappingStats,
                        totalTags: vehicleStats.totalTags,
                        isCompleted: vehicleStats.isCompleted,
                    };
                }
                return v;
            });
        });
    }, []);

    const loadBookStats = useCallback(async bookId => {
        const [bookStats, tagStats] = await Promise.all([
            requestMappingStatistics(bookId),
            requestBookTaggerStats(bookId),
        ]);

        setData(current => {
            current.forEach((b, i) => {
                if (b.bookId === bookId) {
                    current[i] = {
                        ...b,
                        isLoaded: true,
                        mappingStatus: `${bookStats.proceduresCount.completed}/${bookStats.proceduresCount.total} (${(
                            (bookStats.proceduresCount.completed / bookStats.proceduresCount.total) *
                            100
                        ).toFixed(2)}%)`,
                        totalTags: tagStats.totalTagsCount,
                        isCompleted: bookStats.proceduresCount.completed === bookStats.proceduresCount.total,
                    };
                }
            });

            current.forEach((v, i) => {
                if (v.isVehicle) {
                    const booksForVehicle = current.filter(d => !d.isVehicle && d.vehicleRefId === v.vehicleId);
                    current[i] = {
                        ...current[i],
                        isLoaded: booksForVehicle.every(b => b.isLoaded),
                        isCompleted: booksForVehicle.length > 0 && booksForVehicle.every(b => b.isCompleted),
                    };
                }
            });

            return [...current];
        });
    }, []);

    useEffect(() => {
        (async () => {
            try {
                incrementLoading();
                const data = await getVehicles(oemId, modelId);
                setVehicles([...data]);
                setData([...data]);
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                decrementLoading();
            }
        })();
        return () => {
            setVehicles([]);
            setData([]);
        };
    }, [decrementLoading, incrementLoading, modelId, notifications, oemId]);

    useEffect(() => {
        if (!showBooks) return;

        let isMounted = true;
        (async () => {
            try {
                if (vehicles) {
                    let books = [];
                    for (let i = 0; i < vehicles.length && isMounted; i++) {
                        const vehicle = vehicles[i];
                        const booksForVehicle = await getBooksForVehicle(vehicle);
                        setData(currentVehicles => {
                            const vehicleToEditIndex = currentVehicles.findIndex(
                                v => v.vehicleId === vehicle.vehicleId
                            );
                            currentVehicles.splice(vehicleToEditIndex + 1, 0, ...booksForVehicle);
                            return [...currentVehicles];
                        });

                        books = books.concat(booksForVehicle.map(b => b.bookId));
                    }

                    setBooks([...new Set(books)]);
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [notifications, vehicles, showBooks]);

    useEffect(() => {
        if (!showBooks) return;

        let isMounted = true;
        (async () => {
            try {
                if (books) {
                    for (let i = 0; i < books.length && isMounted; i++) {
                        const book = books[i];
                        await loadBookStats(book);
                    }
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [books, loadBookStats, notifications, showBooks]);

    useEffect(() => {
        if (showBooks) return;

        let isMounted = true;
        (async () => {
            try {
                if (vehicles) {
                    for (let i = 0; i < vehicles.length && isMounted; i++) {
                        const vehicle = vehicles[i];
                        await loadVehicleStats(vehicle);
                    }
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [loadVehicleStats, notifications, showBooks, vehicles]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                for (let i = 0; i < vehicles.length && isMounted; i++) {
                    const vehicle = vehicles[i];
                    const vehicleHistory = await requestLastVehiclePublishHistory(vehicle.vehicleId);
                    isMounted &&
                        setData(currentVehicles => {
                            const vehicleToEditIndex = currentVehicles.findIndex(
                                v => v.vehicleId === vehicle.vehicleId
                            );
                            if (vehicleToEditIndex != -1) {
                                currentVehicles[vehicleToEditIndex] = {
                                    ...currentVehicles[vehicleToEditIndex],
                                    datePublished: {
                                        ...currentVehicles[vehicleToEditIndex].datePublished,
                                        vehicleHistory: vehicleHistory,
                                    },
                                };
                            }

                            return [...currentVehicles];
                        });
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [notifications, vehicles]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                for (let i = 0; i < vehicles.length && isMounted; i++) {
                    const vehicle = vehicles[i];
                    const vehiclePublishException = await requestVehiclePublishException(vehicle.vehicleId);
                    if (vehiclePublishException) {
                        isMounted &&
                            setData(currentVehicles => {
                                const vehicleToEditIndex = currentVehicles.findIndex(
                                    v => v.vehicleId === vehicle.vehicleId
                                );
                                currentVehicles[vehicleToEditIndex] = {
                                    ...currentVehicles[vehicleToEditIndex],
                                    publishException: vehiclePublishException.isActive
                                        ? { ...vehiclePublishException }
                                        : clearPublishExceptionData(vehiclePublishException),
                                };

                                return [...currentVehicles];
                            });
                    }
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [notifications, vehicles]);

    function convertToOptions(arrayList) {
        return arrayList.map(item => ({
            value: Number(item.publishExceptionCategoryId),
            label: String(item.publishExceptionCategoryName),
        }));
    }
    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                const categoriesData = convertToOptions(await requestVehiclePublishExceptionCategories());
                isMounted && setCategories(categoriesData);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [notifications]);

    const isFieldChanged = (value1, value2) => {
        return (isEmpty(value1) && isEmpty(value2)) || isEqual(value1, value2);
    };

    const manageVehiclePublisherExceptionTracking = useCallback(
        async (vehicleInfo, typeId, value) => {
            const publishException = { ...vehicleInfo.publishException } || {};
            //validate if value has changed
            //no changes dont update
            switch (typeId) {
                case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_CATEGORY.Id:
                    if (isFieldChanged(String(publishException.publishExceptionCategoryId), String(value), typeId))
                        return;
                    break;
                case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_NOTE.Id:
                    if (isFieldChanged(publishException.note, value, typeId)) return;
                    break;
                case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_TICKET.Id:
                    if (isFieldChanged(publishException.ticket, value, typeId)) return;
                    break;
                default:
                    break;
            }
            //update state and proceed
            setData(currentVehicles => {
                const vehicleToEditIndex = currentVehicles.findIndex(v => v.vehicleId === vehicleInfo.vehicleId);

                const updatedVehicle = {
                    ...currentVehicles[vehicleToEditIndex],
                    publishException: {
                        ...currentVehicles[vehicleToEditIndex].publishException,
                    },
                };

                switch (typeId) {
                    case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_CATEGORY.Id:
                        updatedVehicle.publishException.publishExceptionCategoryId = value;
                        break;
                    case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_NOTE.Id:
                        updatedVehicle.publishException.note = value;
                        break;
                    case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_TICKET.Id:
                        updatedVehicle.publishException.ticket = value;
                        break;
                    default:
                        break;
                }

                const updatedVehicles = [...currentVehicles];
                updatedVehicles[vehicleToEditIndex] = updatedVehicle;

                return updatedVehicles;
            });

            try {
                //check if vehiclePublishExceptionId is available, if available UPDATE else ADD
                if (publishException.vehiclePublishExceptionId) {
                    switch (typeId) {
                        case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_CATEGORY.Id:
                            publishException.publishExceptionCategoryId = value;
                            break;
                        case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_NOTE.Id:
                            publishException.note = value;
                            break;
                        case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_TICKET.Id:
                            publishException.ticket = value;
                            break;
                        default:
                            break;
                    }
                    await requestUpdateVehiclePublishException({
                        VehiclePublishException: { ...publishException, vehicleId: vehicleInfo.vehicleId },
                    });
                } else {
                    const vehicleObj = {
                        vehicleId: vehicleInfo.vehicleId,
                        vehiclePublishExceptionId: 0,
                    };
                    switch (typeId) {
                        case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_CATEGORY.Id:
                            vehicleObj.publishExceptionCategoryId = value;
                            break;
                        case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_NOTE.Id:
                            vehicleObj.note = value;
                            break;
                        case VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_TICKET.Id:
                            vehicleObj.ticket = value;
                            break;
                        default:
                            break;
                    }
                    const addedVehiclePublishException = await requestAddVehiclePublishException({
                        VehiclePublishException: vehicleObj,
                    });
                    //update state with the new vehiclePublishExceptionId for the record
                    setData(currentVehicles => {
                        const vehicleToEditIndex = currentVehicles.findIndex(
                            v => v.vehicleId === vehicleInfo.vehicleId
                        );
                        currentVehicles[vehicleToEditIndex] = {
                            ...currentVehicles[vehicleToEditIndex],
                            publishException: {
                                ...currentVehicles[vehicleToEditIndex].publishException,
                                vehiclePublishExceptionId: addedVehiclePublishException.vehiclePublishExceptionId,
                            },
                        };
                        return [...currentVehicles];
                    });
                }
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }
        },
        [notifications]
    );
    const shouldSetViewMode = useMemo(() => {
        const checkAdminRole = hasRole(roles.admin);
        const checkSiteAdminRole = hasRole(roles.siteAdmin);
        const checkSMERole = hasRole(roles.dataSME);
        return checkAdminRole || checkSiteAdminRole || checkSMERole;
    }, [hasRole]);

    useEffect(() => {
        setEditMode(!shouldSetViewMode);
    }, [shouldSetViewMode]);

    const clearPublishExceptionData = useCallback(exceptionData => {
        return {
            ...exceptionData,
            note: '',
            publishExceptionCategoryId: null,
            ticket: '',
        };
    }, []);

    return {
        data,
        setData,
        categories,
        manageVehiclePublisherExceptionTracking,
        editMode,
        clearPublishExceptionData,
    };
};

export default useVehicles;
