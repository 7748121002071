import React, { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { IngestionButton } from './types';
import SearchBar from 'components/Shared/SearchBar/SearchBar';

interface IngestionManagerSearchProps {
    onSearch: (filter: string, radioValue: IngestionButton) => void;
}

const IngestionManagerSearch = ({ onSearch }: IngestionManagerSearchProps) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
        event.key === 'Enter' && event.currentTarget.blur();

    const [radioValue, setRadioValue] = useState<IngestionButton>(IngestionButton.None);

    const handleSearchBlur = (e?: React.FocusEvent<HTMLInputElement, Element>) => {
        const value = e?.currentTarget.value.trim() ?? '';
        setSearchValue(value);
        onSearch(value, radioValue);
    };

    return (
        <div className="d-flex flex-row align-items-center">
            <SearchBar
                id={'ingestion-manager-search'}
                value={searchValue}
                onChange={setSearchValue}
                onBlur={handleSearchBlur}
                onKeyDown={e => handleSearchKeyPress(e)}
            />
            <ButtonGroup className="ingestion-process-btngroup px-2 py-3">
                <ToggleButton
                    value={'newButton'}
                    id="filterNewButton"
                    className="btn-filter"
                    title="Filter to vehicles that haven't been reviewed"
                    type="radio"
                    name="radio"
                    variant={radioValue === IngestionButton.NewButton ? 'secondary' : 'outline-secondary'}
                    checked={radioValue === IngestionButton.NewButton}
                    onClick={() => {
                        const newValue =
                            radioValue === IngestionButton.NewButton ? IngestionButton.None : IngestionButton.NewButton;
                        setRadioValue(newValue);
                        onSearch(searchValue, newValue);
                    }}>
                    {'New (off)'}
                </ToggleButton>
                <ToggleButton
                    value={'onButton'}
                    id="filterOnButton"
                    className="btn-filter"
                    title="Filter to vehicles that have been reviewed and will be scrapped and imported during the next refresh"
                    type="radio"
                    name="radio"
                    variant={radioValue === IngestionButton.OnButton ? 'success' : 'outline-secondary'}
                    checked={radioValue === IngestionButton.OnButton}
                    onClick={() => {
                        const newValue =
                            radioValue === IngestionButton.OnButton ? IngestionButton.None : IngestionButton.OnButton;
                        setRadioValue(newValue);
                        onSearch(searchValue, newValue);
                    }}>
                    {'On'}
                </ToggleButton>
                <ToggleButton
                    value={'offButton'}
                    id="filterOffButton"
                    className="btn-filter"
                    title="Filter to vehicles that have been reviewed and will NOT be scrapped and imported during the next refresh"
                    type="radio"
                    name="radio"
                    variant={radioValue === IngestionButton.OffButton ? 'danger' : 'outline-secondary'}
                    checked={radioValue === IngestionButton.OffButton}
                    onClick={() => {
                        const newValue =
                            radioValue === IngestionButton.OffButton ? IngestionButton.None : IngestionButton.OffButton;
                        setRadioValue(newValue);
                        onSearch(searchValue, newValue);
                    }}>
                    {'Off'}
                </ToggleButton>
            </ButtonGroup>
        </div>
    );
};

export default IngestionManagerSearch;
