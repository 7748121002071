import React, { useCallback, useContext, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { MappingDefinition, MappingDefinitionType } from 'contexts/types';

interface TypeListProps {
    filter;
    setFilterValue;
}

export const TypeList = ({ filter, setFilterValue }: TypeListProps) => {
    const { types } = useContext<MappingDefinition>(MappingDefinitionsContext);
    const typeOptionsList: { label: string; value: number }[] = useMemo(
        () => types.map(t => ({ label: t.oemIqSectionName, value: t.oemIqSectionId })),
        [types]
    );

    const [inputValue, setInputValue] = useState((filter.valueList && filter.valueList[0]) || '');

    const handleChange = useCallback(
        e => {
            const v = e.target.value;
            const val = types.filter(t => t.oemIqSectionName.match(new RegExp(v, 'i')));
            setInputValue(v);
            setFilterValue({
                value: val,
                valueList: [v],
            });
        },
        [types, setFilterValue]
    );

    const [selectValue, setSelectValue] = useState(
        (filter.valueList && {
            value: types.find(t => t.oemIqSectionId === filter.value),
            label: filter.valueList[0],
        }) || {
            value: -1,
            label: '',
        }
    );

    const handleSelect = useCallback(
        v => {
            const val = types.find(t => t.oemIqSectionId === v.value);
            setSelectValue(v);
            setFilterValue({
                value: [val],
                valueList: [v.label],
            });
        },
        [types, setFilterValue]
    );

    return filter.operator.value !== operatorsList.contains.value &&
        filter.operator.value !== operatorsList.notContains.value ? (
        <ReactSelect
            className="mb-2 basic-single"
            classNamePrefix="select"
            placeholder="Choose value"
            options={typeOptionsList}
            isDisabled={!filter.id || !filter.operator}
            value={selectValue}
            onChange={handleSelect}
        />
    ) : (
        types?.length && <input type="text" className="form-control" onChange={handleChange} value={inputValue} />
    );
};

const operatorsList = {
    contains: {
        value: 'contains',
        label: 'contains',
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName}:(${values.map(t => t.oemIqSectionId).join(' OR ')})`,
        buildFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} in (${values.map(t => t.oemIqSectionId).join(', ')})`,
    },
    notContains: {
        value: 'notContains',
        label: 'not contains',
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `!${propertyName}:(${values.map(t => t.oemIqSectionId).join(' OR ')})`,
        buildFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} in (${values.map(t => t.oemIqSectionId).join(', ')}) eq false`,
    },
    eq: {
        value: 'eq',
        label: '==',
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName}:(${values[0].oemIqSectionId})`,
        buildFilter: (propertyName: string, value: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} eq ${value[0].oemIqSectionId}`,
    },
    ne: {
        value: 'ne',
        label: '!=',
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `!${propertyName}:(${values[0].oemIqSectionId})`,
        buildFilter: (propertyName: string, value: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} ne ${value[0].oemIqSectionId}`,
    },
};

export default {
    component: TypeList,
    format: v => v,
    formatES: v => v,
    operators: [operatorsList.contains, operatorsList.notContains, operatorsList.eq, operatorsList.ne],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};
