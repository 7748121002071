import React, { useCallback, useContext, useState } from 'react';
import { Input } from 'oemiq-common';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';

export const InputGroupNameEqualsOperator = ({ onChange, ...props }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);
    const [inputValue, setInputValue] = useState('');

    const handleChange = useCallback(
        e => {
            const v = e.target.value;
            setInputValue(v);
            onChange(mappingDefinitions.groups.filter(g => g.label.match(new RegExp(v, 'i'))));
        },
        [onChange, mappingDefinitions.groups]
    );

    return (
        mappingDefinitions?.groups?.length && (
            <Input type="text" onChange={handleChange} {...props} value={inputValue} />
        )
    );
};
