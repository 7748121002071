import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from './items/Breadcrumb';
import useOemInfo from './items/useOemInfo';

const subtools = {
    refreshpublisher: 'Refresh Publisher',
    'import-manager': 'Import Manager',
    'ingestion-manager': 'Ingestion Manager',
};

const RefreshManagerNavigation = () => {
    const { subtool, oemId, importJobId } = useParams();
    const { oemName } = useOemInfo(oemId);

    return (
        <ol className="breadcrumb">
            <Breadcrumb isShown={true} isCurrent={!subtool} link={'/refreshmanager'} text={'Refresh Manager'} />
            {subtool === 'refreshpublisher' && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/refreshmanager/${subtool}`}
                    text={subtools[subtool]}
                />
            )}
            {subtool === 'refreshpublisher' && oemId && <Breadcrumb isShown={true} isCurrent={true} text={oemName} />}
            {subtool === 'import-manager' && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/refreshmanager/${subtool}`}
                    text={subtools[subtool]}
                />
            )}
            {subtool === 'import-manager' && oemId && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!importJobId}
                    link={`/refreshmanager/${subtool}/${oemId}`}
                    text={oemName}
                />
            )}
            {subtool === 'import-manager' && importJobId && (
                <Breadcrumb isShown={true} isCurrent={true} text={importJobId} />
            )}
            {subtool === 'ingestion-manager' && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/refreshmanager/${subtool}`}
                    text={subtools[subtool]}
                />
            )}
            {subtool === 'ingestion-manager' && oemId && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/refreshmanager/${subtool}/${oemId}`}
                    text={oemName}
                />
            )}
        </ol>
    );
};

export default RefreshManagerNavigation;
