import React from 'react';
import { Input } from 'oemiq-common';
import { operatorsList } from '../operators';

export const Number = ({ filter, setFilterValue }) => {
    return (
        <Input
            className="mb-2"
            type="number"
            step="1"
            placeholder="Filter value"
            disabled={!filter.id || !filter.operator}
            value={filter.value}
            onChange={e => setFilterValue({ value: e.target.value, valueList: null })}
        />
    );
};

export default {
    component: Number,
    format: v => v,
    formatES: v => v,
    operators: [
        operatorsList.eq,
        operatorsList.ne,
        operatorsList.lt,
        operatorsList.gt,
        operatorsList.le,
        operatorsList.ge,
    ],
    defaultValue: 0,
    allowFalse: false,
    allowInstances: 2,
};
