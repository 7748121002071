import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { requestMapProceduresToType, requestSearchProcedures } from 'api/RepairProcedureApi';
import useOemRawProcedure from 'hooks/useOemRawProcedure';
import useProcedures from 'hooks/useProcedures';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import useRemovedProcedures from 'hooks/useRemovedProcedures';

const mergeProcedureData = (rpProcedure, oemProcedure) => {
    if (!oemProcedure) return rpProcedure;

    const { type, group, procedureTitle, ...oemRaw } = oemProcedure;
    const merged = Object.assign(rpProcedure, oemRaw); // same reference like rpProcedure

    if (type) rpProcedure.oemType = type; //Note: To avoid spread collision with same named properties
    if (group) rpProcedure.oemGroup = group; //Note: To avoid spread collision with same named properties
    if (procedureTitle) rpProcedure.oemProcedureTitle = procedureTitle; //Note: To avoid spread collision with same named properties
    return merged;
};

const filterDynamicColumnKeys = keys => {
    const forbidden = [
        'procedureId',
        'rpProcedureId',
        'vehicles',
        'createDate',
        'updateDate',
        'stageArea',
        'isDeleted',
    ];
    return keys.filter(k => !forbidden.includes(k));
};

const hideDynamicKeyList = ['groups', 'type'];

const useMapperList = () => {
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const [dynamicProcedureKeys, setDynamicProcedureKeys] = useState([]);
    let { bookId, oemId } = useParams();
    const {
        procedures,
        updateOemIqTypeByProcedureId,
        setNewGroupListToProcedureByProcedureId,
        updateProcedures,
        getProcedures,
        updateOemIqTypeForProcedureIds,
    } = useProcedures(bookId);
    const { removedProcedures } = useRemovedProcedures(bookId);
    const { oemRawProcedures } = useOemRawProcedure(oemId, bookId);
    const { oemRawProcedures: oemRawRemovedProcedures } = useOemRawProcedure(oemId, bookId, true);
    const [searchResults, setSearchResults] = useState(null);
    const [showColumnSettings, setShowColumnSettings] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [noGroupFilter, setNoGroupFilter] = useState(false);
    const [noTypeFilter, setNoTypeFilter] = useState(false);
    const [refreshedFilter, setRefreshedFilter] = useState(false);
    const [onlyHotSheetFilter, setOnlyHotSheetFilter] = useState(false);
    const [isBulkActionHistoryModalOpen, setIsBulkActionHistoryModalOpen] = useState(false);

    const { oemRawProceduresMap, oemRawRemovedProceduresMap } = useMemo(() => {
        return {
            oemRawProceduresMap: new Map(oemRawProcedures?.map(orp => [orp.rpProcedureId, orp])),
            oemRawRemovedProceduresMap: new Map(oemRawRemovedProcedures?.map(orp => [orp.rpProcedureId, orp])),
        };
    }, [oemRawProcedures, oemRawRemovedProcedures]);

    const { mergedProcedureDetailsList, mergedRemovedProcedureDetailsList } = useMemo(() => {
        return {
            mergedProcedureDetailsList: procedures?.map(p =>
                mergeProcedureData(p, oemRawProceduresMap.get(p.procedureId))
            ),
            mergedRemovedProcedureDetailsList: removedProcedures?.map(p =>
                mergeProcedureData(p, oemRawRemovedProceduresMap.get(p.procedureId))
            ),
        };
    }, [oemRawProceduresMap, oemRawRemovedProceduresMap, procedures, removedProcedures]);

    const mappingProgress = useMemo(() => {
        const groupsMapped = procedures.filter(p => p.stageArea.groups.length > 0).length;
        const typeMapped = procedures.filter(p => p.type !== null).length;
        const total = procedures.length;
        const groupPercentage = ((groupsMapped / total) * 100).toFixed(1);
        const typePercentage = ((typeMapped / total) * 100).toFixed(1);

        return {
            group: groupsMapped,
            type: typeMapped,
            total: total,
            groupPercentage: groupPercentage,
            typePercentage: typePercentage,
        };
    }, [procedures]);

    useEffect(() => {
        if (procedures.length > 0 && oemRawProcedures.length > 0) {
            const element = mergeProcedureData(
                procedures[0],
                oemRawProcedures.find(orp => orp.rpProcedureId === procedures[0].procedureId)
            );
            let allKeys = Object.keys(element);
            let columnSettingsCookie = localStorage.getItem(`${oemId}-adminPortalColumnSettings`);
            let dynamicKeys = filterDynamicColumnKeys(allKeys);
            dynamicKeys.sort((a, _b) => (a === 'procedureTitle' || a === 'stageAreaIsDeleted' ? -1 : 1));

            if (columnSettingsCookie) {
                let obj = JSON.parse(columnSettingsCookie);
                obj = obj.filter(f => dynamicKeys.includes(f.text)); //Filter any columns out of local settings that don't exist in dynamic keys (properties of procedure object)
                dynamicKeys.forEach(d => {
                    if (obj.filter(o => o.text === d).length === 0) {
                        obj.push({ id: d, text: d, isHidden: false, width: 'initial' }); //If any columns are missing from local settings, add them in
                    }
                });
                setDynamicProcedureKeys(obj);
            } else {
                let keyObjects = [];
                dynamicKeys.forEach(k => {
                    keyObjects.push({ id: k, text: k, isHidden: false, width: 'initial' });
                });
                setDynamicProcedureKeys(keyObjects);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedures.length, oemRawProcedures, oemId]);

    const updateProcedureOemiqType = useCallback(
        async (newOemIqType, procedureId) => {
            try {
                await requestMapProceduresToType(newOemIqType.oemIqSectionId, [procedureId]);
                updateOemIqTypeByProcedureId(newOemIqType, procedureId);
            } catch (error) {
                showToast(error);
            }
        },
        [showToast, updateOemIqTypeByProcedureId]
    );

    const searchProcedures = useCallback(
        async (searchTerm, searchValueSelections) => {
            if (searchTerm === '') {
                setSearchResults(null);
                return;
            }
            let bookSearchQuery = {
                bookId: parseInt(bookId),
                searchText: searchTerm,
                skip: 0,
                top: procedures.length,
                searchFields: searchValueSelections,
            };

            try {
                incrementLoading();
                const res = await requestSearchProcedures(bookSearchQuery);
                setSearchResults(res);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        },
        [bookId, decrementLoading, incrementLoading, procedures.length, showToast]
    );

    const saveColumnSettingsLocalStorage = useCallback(
        newDynamicProcedureKeys => {
            let dynamicProcedureKeysString = JSON.stringify(newDynamicProcedureKeys);
            localStorage.setItem(`${oemId}-adminPortalColumnSettings`, dynamicProcedureKeysString);
        },
        [oemId]
    );

    const handleSaveColumnSettings = useCallback(
        newDynamicProcedureKeys => {
            newDynamicProcedureKeys.sort((a, b) => {
                return a.isHidden - b.isHidden;
            });

            saveColumnSettingsLocalStorage(newDynamicProcedureKeys);
            setDynamicProcedureKeys(newDynamicProcedureKeys);
            setShowColumnSettings(false);
        },
        [saveColumnSettingsLocalStorage]
    );

    const updateColumnWidth = useCallback(
        (key, newWidth) => {
            let newDynamicKeys = [...dynamicProcedureKeys];
            let index = newDynamicKeys.findIndex(d => d.text === key);
            newDynamicKeys[index].width = newWidth;
            saveColumnSettingsLocalStorage(newDynamicKeys);
            setDynamicProcedureKeys(newDynamicKeys);
        },
        [dynamicProcedureKeys, saveColumnSettingsLocalStorage]
    );

    const handleBulkTaskHistoryToggle = useCallback(() => {
        setIsBulkActionHistoryModalOpen(prev => !prev);
    }, []);

    const handleBulkTaskHistoryButtonClick = useCallback(() => {
        setIsBulkActionHistoryModalOpen(true);
    }, []);

    return {
        mergedProcedureDetailsList,
        mergedRemovedProcedureDetailsList,
        oemRawProceduresMap,
        procedures,
        updateProcedureOemiqType,
        setNewGroupListToProcedureByProcedureId,
        dynamicProcedureKeys,
        searchProcedures,
        searchResults,
        handleSaveColumnSettings,
        showColumnSettings,
        setShowColumnSettings,
        updateColumnWidth,
        appliedFilters,
        setAppliedFilters,
        hideDynamicKeyList,
        noGroupFilter,
        setNoGroupFilter,
        noTypeFilter,
        setNoTypeFilter,
        refreshedFilter,
        setRefreshedFilter,
        onlyHotSheetFilter,
        setOnlyHotSheetFilter,
        mappingProgress,
        updateProcedures,
        refreshProcedures: getProcedures,
        updateOemIqTypeForProcedureIds,
        isBulkActionHistoryModalOpen,
        handleBulkTaskHistoryToggle,
        handleBulkTaskHistoryButtonClick,
    };
};

export default useMapperList;
