import { ProcedurePropertyMetadata } from './ProcedureProperty';

export interface FilterProperty {
    id: string;
    property: string;
    label: string;
    type;
    ruleable: boolean;
    allowInstances: number;
}

export interface OemMetadata {
    oemId: number;
    oemName: string;
    properties: ProcedurePropertyMetadata<unknown, unknown>[];
    filters: FilterProperty[];
    ruleKey: string;
    ruleable: ProcedurePropertyMetadata<unknown, unknown>[];
}

export const buildOemMetadata = ({
    oemId,
    oemName,
    properties,
    ruleKey,
    filters = null,
}: {
    oemId: number;
    oemName: string;
    properties: ProcedurePropertyMetadata<unknown, unknown>[];
    ruleKey: string;
    filters?: FilterProperty[];
}): OemMetadata => ({
    oemId,
    oemName,
    properties,
    ruleKey,
    ruleable: properties.filter(p => p.ruleable),
    filters: filters ?? [],
});
