import React from 'react';
import IconButton from './IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ColumnSettingItemProps = {
    columnId: string;
    text: string;
    isHidden: boolean;
    dropColumnId: string;
    columnDragEnter: (event, enteredColumnId: string) => void;
    columnDrop: (event) => void;
    columnDragOver: (event) => void;
    onHiddenColumnChange: (columnId: string) => void;
    columnDragStart: (event, draggingColumnId: string) => void;
    columnDragEnd: (event) => void;
};

const ColumnSettingItem = ({
    columnId,
    text,
    isHidden,
    dropColumnId,
    columnDragEnter,
    columnDrop,
    columnDragOver,
    onHiddenColumnChange,
    columnDragStart,
    columnDragEnd,
}: ColumnSettingItemProps) => {
    return (
        <div
            className={`d-flex align-content-center mb-2${dropColumnId === columnId ? ' border-top' : ''}`}
            onDragEnter={e => columnDragEnter(e, columnId)}
            onDrop={columnDrop}
            onDragOver={columnDragOver}>
            <IconButton
                id={`toggle-column-${columnId}`}
                btnColorClassName={`${isHidden ? 'btn-secondary' : 'btn-primary'}`}
                onClick={() => onHiddenColumnChange(columnId)}>
                {isHidden ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
            </IconButton>
            <div
                className={`ms-2 ${!isHidden && 'grabbable'}`}
                draggable={!isHidden}
                onDragStart={e => columnDragStart(e, columnId)}
                onDragEnd={columnDragEnd}>
                <FontAwesomeIcon
                    className={`${isHidden ? 'text-secondary' : 'text-primary'} fa-lg`}
                    icon="grip-vertical"
                />
            </div>
            <div className={`ms-2 ${isHidden && 'opacity-15'}`}>{text}</div>
        </div>
    );
};

export default ColumnSettingItem;
