import React, { useCallback, useState } from 'react';
import ReactSelect from 'react-select';
import { match } from 'ts-pattern';
import { MappingWorkflowStatus } from 'types/procedureTypes';

enum MappingStatusListOption {
    InReview,
    Complete,
    NeedHelp,
}

const mappingStatusList = [
    { value: MappingStatusListOption.InReview, label: 'In Review' },
    { value: MappingStatusListOption.Complete, label: 'Complete' },
    { value: MappingStatusListOption.NeedHelp, label: 'Need Help' },
];

type MappingStatusListProps = {
    filter;
    setFilterValue;
};

export const MappingStatusList = ({ filter, setFilterValue }: MappingStatusListProps) => {
    const [selectValue, setSelectValue] = useState<{ value: number; label: string }>(
        (filter.value && filter.value[0]) || {}
    );

    const handleSelect = useCallback(
        v => {
            setSelectValue(v);
            setFilterValue({
                value: [v],
                valueList: [v.label],
            });
        },
        [setFilterValue]
    );

    return (
        <ReactSelect
            className="mb-2 basic-single"
            classNamePrefix="select"
            placeholder="Choose value"
            options={mappingStatusList}
            isDisabled={!filter.id || !filter.operator}
            value={selectValue}
            onChange={handleSelect}
        />
    );
};

const getESMappingStatusFilter = (value: MappingStatusListOption, equal: boolean) => {
    const equalFilter = (filter: string, equal: boolean) => (equal ? filter : `!(${filter})`);

    return match(value)
        .with(MappingStatusListOption.InReview, () =>
            equalFilter(`mappingStatusId:${MappingWorkflowStatus.InReview}`, equal)
        )
        .with(MappingStatusListOption.Complete, () =>
            equalFilter(`mappingStatusId:${MappingWorkflowStatus.Completed}`, equal)
        )
        .with(MappingStatusListOption.NeedHelp, () =>
            equalFilter(`mappingStatusId:${MappingWorkflowStatus.NeedHelp}`, equal)
        )
        .run();
};

const getSQLMappingStatusFilter = (value: MappingStatusListOption, equal: boolean) => {
    const hotSheetGroupId = 80;
    const isActiveFilter = 'isDeleted eq false';
    const hasGroupOrTypeFilter = '(stageArea/type/typeId ne null or stageArea/groups/any())';
    const hasGroupAndTypeFilter = 'stageArea/type/typeId ne null and stageArea/groups/any()';
    const hasAnyGroupBesideHotSheet = `stageArea/groups/$count ge 1 and stageArea/groups/any(group: group/groupId ne ${hotSheetGroupId})`;
    const hasAnyGroupOrTypeInStatusFilter = (value: MappingWorkflowStatus) => {
        if (value === MappingWorkflowStatus.InReview) {
            return `(stageArea/groups/any(group: group/mappingStatusId eq ${value}) or stageArea/type/mappingStatusId eq ${value} or stageArea/type/mappingStatusId eq null)`;
        }

        return `(stageArea/groups/any(group: group/mappingStatusId eq ${value}) or stageArea/type/mappingStatusId eq ${value})`;
    };
    const hasEveryGroupAndTypeInStatusFilter = (value: MappingWorkflowStatus) =>
        `stageArea/groups/all(group: group/mappingStatusId eq ${value}) and stageArea/type/mappingStatusId eq ${value}`;

    const equalFilter = (filter: string, equal: boolean) => (equal ? filter : `not (${filter})`);

    return match(value)
        .with(MappingStatusListOption.InReview, () =>
            equalFilter(
                `${isActiveFilter} and ${hasGroupAndTypeFilter} and ${hasAnyGroupBesideHotSheet} and not ${hasAnyGroupOrTypeInStatusFilter(
                    MappingWorkflowStatus.NeedHelp
                )} and ${hasAnyGroupOrTypeInStatusFilter(MappingWorkflowStatus.InReview)}`,
                equal
            )
        )
        .with(MappingStatusListOption.Complete, () =>
            equalFilter(
                `${isActiveFilter} and ${hasGroupAndTypeFilter} and ${hasAnyGroupBesideHotSheet} and ${hasEveryGroupAndTypeInStatusFilter(
                    MappingWorkflowStatus.Completed
                )}`,
                equal
            )
        )
        .with(MappingStatusListOption.NeedHelp, () =>
            equalFilter(
                `${isActiveFilter} and ${hasGroupOrTypeFilter} and ${hasAnyGroupOrTypeInStatusFilter(
                    MappingWorkflowStatus.NeedHelp
                )}`,
                equal
            )
        )
        .run();
};

const operatorsList = {
    eq: {
        value: 'eq',
        label: '==',
        buildESFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getESMappingStatusFilter(value[0].value, true),
        buildFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getSQLMappingStatusFilter(value[0].value, true),
    },
    ne: {
        value: 'ne',
        label: '!=',
        buildESFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getESMappingStatusFilter(value[0].value, false),
        buildFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getSQLMappingStatusFilter(value[0].value, false),
    },
};

export default {
    component: MappingStatusList,
    format: v => v,
    formatES: v => v,
    operators: [operatorsList.eq, operatorsList.ne],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};
