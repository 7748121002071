import { useState, useEffect, useContext } from 'react';
import { MapperListContext } from 'contexts/MapperListContext';

const useDynamicTableHeading = (columnDetails, updateColumnWidth) => {
    const [localWidth, setLocalWidth] = useState('initial');
    const { hideDynamicKeyList } = useContext(MapperListContext);

    useEffect(() => {
        let headerBeingResized;
        let width;
        const onMouseMove = e =>
            requestAnimationFrame(() => {
                let horizontalScrollOffset = document.querySelector('.table').scrollLeft;
                if (headerBeingResized !== null) {
                    width = horizontalScrollOffset + e.clientX - headerBeingResized.offsetLeft - 14;
                    setLocalWidth(width);
                }
            });

        const onMouseUp = () => {
            updateColumnWidth(columnDetails.text, width);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            headerBeingResized.querySelector('.resize-handle').classList.remove('resize-handle-selected');
            headerBeingResized = null;
            width = null;
        };

        const initResize = e => {
            headerBeingResized = e.target.parentNode;
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
            headerBeingResized.querySelector('.resize-handle').classList.add('resize-handle-selected');
        };

        if (columnDetails) {
            const element = document.querySelector(`#dynamic-th-${columnDetails.text}`);
            if (element) element.querySelector('.resize-handle').addEventListener('mousedown', initResize);
            setLocalWidth(columnDetails.width);
        }

        return () => {
            const element = document.querySelector(`#dynamic-th-${columnDetails.text}`);
            if (element) element.querySelector('.resize-handle').removeEventListener('mousedown', initResize);
        };
    }, [columnDetails, updateColumnWidth]);

    return { localWidth, hideDynamicKeyList };
};

export default useDynamicTableHeading;
