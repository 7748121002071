import React from 'react';
import { isFilterRulable } from './operators';

const TableFiltersItem = ({ filter, index, onRemove, onOpenEdit }) => {
    return (
        <li
            key={filter.id}
            className={`filter me-1 ${isFilterRulable(filter) ? 'bg-success' : 'bg-primary'}`}
            title="Edit filter"
            onClick={() => onOpenEdit(filter, index)}>
            <div className="filter-name">{filter.label}</div>
            <div className="filter-operation" style={{ backgroundColor: 'rgba(200, 200, 200, 0.6)' }}>
                <span>{filter.operator.label}</span>
            </div>
            <div className="filter-value" title={filter.valueList ? filter.valueList.join(', ') : filter.value}>
                {filter.valueList
                    ? filter.valueList.map(v => (
                          <span className="badge text-bg-secondary me-1" key={v}>
                              {v}
                          </span>
                      ))
                    : filter.value}
            </div>
            <div className="filter-remove" title="Remove filter" onClick={e => (e.stopPropagation(), onRemove(index))}>
                &times;
            </div>
        </li>
    );
};

export default React.memo(TableFiltersItem);
