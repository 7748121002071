import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavigationTile.scss';

const NavigationTile = ({ path, text, icon, id, tileDescription, display, notificationCount }) => {
    const [showInfo, setShowInfo] = useState(false);

    const handleInfoClick = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowInfo(currVal => !currVal);
    };

    return (
        <div className={`navigation-tile col-3 mt-4 d-${display ? 'block' : 'none'}`}>
            <Link to={path}>
                <div
                    className="border position-relative border-dark rounded text-center d-flex align-items-center justify-content-center p-2 card-shadow"
                    id={id}
                    style={{ height: '150px' }}>
                    {showInfo ? (
                        <h6>{tileDescription}</h6>
                    ) : (
                        <div>
                            <FontAwesomeIcon icon={icon} size="3x" />
                            <h4 className="text-center mt-3">{text}</h4>
                            {notificationCount > 0 && (
                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                                    data-testid="notification-count">
                                    {notificationCount} New!
                                </span>
                            )}
                        </div>
                    )}
                    <FontAwesomeIcon
                        icon="info-circle"
                        className="position-absolute bottom-0 end-0 me-2 mb-2"
                        onClick={e => handleInfoClick(e)}
                    />
                </div>
            </Link>
        </div>
    );
};
export default NavigationTile;
