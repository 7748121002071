export interface ColumnData {
    id: string;
    label: string;
    rulePropertyName: string;
    operator: Operator;
    hasActiveFilter: boolean;
    value: string;
}

export interface ColumnValues {
    id: string;
    values: string[];
    count: number;
    isLoading: boolean;
    isLoadAll: boolean;
}

export interface ColumnChangeCommand {
    action: ColumnChangeAction;
    propertyName: string;
    id: string;
    operator: Operator;
    value: string;
}

export enum Operator {
    Equal = 'Equal',
    Contains = 'Contains',
    NotContains = 'NotContains',
}

export enum ColumnChangeAction {
    SetOperator = 'set-operator',
    SetContainsText = 'set-contains-text',
    SetValue = 'set-value',
}

export const OPERATORS: { [operatorId: string]: { label: string; value: Operator } } = {
    [Operator.Equal]: { label: 'equal', value: Operator.Equal },
    [Operator.Contains]: { label: 'contains', value: Operator.Contains },
    [Operator.NotContains]: { label: 'not contains', value: Operator.NotContains },
};

export const OPERATORS_CATEGORIES = [[Operator.Equal], [Operator.Contains, Operator.NotContains]];
