import { useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react';
import { requestOrganizationCompanies, requestOrganizationSegments, mergeOrganizations } from 'api/SecurityApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faThumbTack, faBoxesStacked, faCirclePause, faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import { useNavigate, useLocation } from 'react-router-dom';
import { CUST_TOOLS, OEC_STAGING_ORG } from 'components/locations/ManageCustomers/ManageCustomersConstants';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { movePlansToOrganizations } from 'api/RepairPlanApi';
import ApiService from 'api/ApiService';
import { ApiEndpoints } from 'api/config/ApiEndpoints';
import withButton from '../../../Shared/Utils/withButton';
import { useSearchParams } from 'react-router-dom';
import { globalSearchType, testOrganizations } from '../../../Shared/Utils/Constants';
import { faMerge } from '@fortawesome/pro-light-svg-icons';
import { messages } from 'components/Shared/Utils/Constants';

const useOrganizations = isExternalRequest => {
    // higher order states
    const navigate = useNavigate();
    const location = useLocation();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const { hasRole, useProtoType } = useContext(AccessControlContext);
    const { notifications } = useContext(NotificationsContext);
    // local states
    const [organizations, setOrganizations] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayOrgs, setDisplayOrgs] = useState([]);
    const [editOrgId, setEditOrgId] = useState(null);
    const [showMergeOrganizationModal, setShowMergeOrganizationModal] = useState(false);
    const [showMergeOrgConfirmationModal, setShowMergeOrgConfirmationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [sortedPropertyKey, setSortedPropertyKey] = useState(null);
    const [sortedPropertyDirection, setSortedPropertyDirection] = useState(null);
    const userHasCustomerSupportRole = useMemo(() => hasRole(roles.customerSupport), [hasRole]);
    const [numStagedComps, setNumStagedComps] = useState(-1);
    const [segments, setSegments] = useState([]);
    const [selectedOrgData, setSelectedOrgData] = useState(null);
    const [searchParams] = useSearchParams();
    const searchParamKey = searchParams.get('searchKey');
    const searchParamText = searchParams.get('search');
    const globalSearchRef = useRef(null);

    const [tableColumnDetails, setTableColumnDetails] = useState([
        { title: 'Id', columnProperty: 'organizationId', sortable: true },
        { title: 'Name', columnProperty: 'name', sortable: true },
        { title: 'Contact Person', columnProperty: 'contactPersonFirstName', sortable: true },
        { title: 'Address', columnProperty: 'address', sortable: true },
        { title: 'City', columnProperty: 'city', sortable: true },
        { title: 'State', columnProperty: 'state', sortable: true },
        { title: 'ZIP', columnProperty: 'zip', sortable: true },
        { title: 'Country', columnProperty: 'countryName', sortable: true },
        { title: 'Segment', columnProperty: 'segmentName', sortable: true },
        { title: 'Note', columnProperty: 'notes', sortable: true },
        {
            title: 'Status',
            columnProperty: 'status',
            textProperty: 'statusText',
            sortable: true,
            filterable: true,
        },
    ]);

    useEffect(() => {
        setTableColumnDetails(config =>
            config.map(columnDetails => {
                let updatedColumnDetails = { ...columnDetails };
                if (searchParamText !== null && updatedColumnDetails.columnProperty === 'status') {
                    updatedColumnDetails.filterable = true;
                }
                if (updatedColumnDetails.filterable) {
                    if (!updatedColumnDetails.filterConfig) {
                        updatedColumnDetails.filterConfig = {};
                    }
                    updatedColumnDetails.filterConfig.filterOptions = Array.from(
                        new Set(
                            Array.from(
                                new Set(
                                    organizations.map(
                                        organization =>
                                            organization[updatedColumnDetails.textProperty] ??
                                            organization[updatedColumnDetails.columnProperty]
                                    )
                                )
                            ).map(x => ({
                                label: x,
                                value: x,
                            }))
                        )
                    );
                }
                return updatedColumnDetails;
            })
        );
    }, [organizations, searchParamText]);

    const [selectedFilters, setSelectedFilters] = useState([]);

    useEffect(() => {
        if (
            userHasCustomerSupportRole ||
            !organizations ||
            organizations.length === 0 ||
            organizations.some(o => o.name === OEC_STAGING_ORG.name) ||
            searchParamText ||
            searchParamKey
        ) {
            return;
        }

        const getOrgCompanies = async () => {
            try {
                incrementLoading();
                const stagedComps = await requestOrganizationCompanies(OEC_STAGING_ORG.organizationId);
                setNumStagedComps(stagedComps.length);
                location.state = { ...location.state, stagedComps: stagedComps };
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        if (location && location.state && location.state.stagedComps && location.state.stagedComps.length > 0) {
            setNumStagedComps(location.state.stagedComps.length);
        } else {
            getOrgCompanies();
        }
    }, [
        organizations,
        userHasCustomerSupportRole,
        incrementLoading,
        decrementLoading,
        showToast,
        location,
        searchParamKey,
        searchParamText,
    ]);

    // handle user-requested sorting and searching
    useEffect(() => {
        if (organizations.length > 0) {
            let newSortedAndSearchedOrganizations = organizations.map(o => ({ ...o }));

            if (sortedPropertyKey !== null) {
                if (sortedPropertyDirection === 'A')
                    newSortedAndSearchedOrganizations.sort((a, b) => {
                        if (sortedPropertyKey === 'organizationId')
                            return a[sortedPropertyKey] < b[sortedPropertyKey] ? -1 : 1;
                        else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? -1 : 1;
                    });
                else
                    newSortedAndSearchedOrganizations.sort((a, b) => {
                        if (sortedPropertyKey === 'organizationId')
                            return a[sortedPropertyKey] < b[sortedPropertyKey] ? 1 : -1;
                        else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? 1 : -1;
                    });
            }

            // todo: plug this into fuzzy search
            if (searchTerm) {
                newSortedAndSearchedOrganizations = newSortedAndSearchedOrganizations.filter(
                    o =>
                        o.organizationId.toString().indexOf(searchTerm) > -1 ||
                        (o.name && o.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (o.address && o.address.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (o.zip && o.zip.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (o.city && o.city.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (o.state && o.state.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (o.countryName && o.countryName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (o.segmentName && o.segmentName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (o.notes && o.notes.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
                );
            }

            newSortedAndSearchedOrganizations.forEach(org => {
                // if user is customer support hide edit org button
                if (userHasCustomerSupportRole) {
                    org.actions = [];
                } else if (org.isStagingOrg) {
                    org.actions = useProtoType
                        ? [
                              <FontAwesomeIcon
                                  key={`staging-${org.orgId}`}
                                  className="text-primary clickable customer-action-button"
                                  id={`org-row-staging-access`}
                                  icon={faBoxesStacked}
                                  onClick={() => handleRowClick(OEC_STAGING_ORG)}
                              />,
                          ]
                        : [];
                } else {
                    // draw the edit button
                    const EditAction = withButton(() => (
                        <FontAwesomeIcon
                            className="text-primary clickable customer-action-button"
                            id={`org-row-${org.organizationId}-edit`}
                            icon={faEdit}
                        />
                    ));
                    const MergeOrganizationsAction = withButton(() => (
                        <FontAwesomeIcon
                            className="text-primary clickable customer-action-button"
                            id={`org-row-${org.organizationId}-merge`}
                            icon={faMerge}
                        />
                    ));
                    const UpdateStatusAction = withButton(() => (
                        <FontAwesomeIcon
                            className="text-primary clickable customer-action-button"
                            title={org.isActive ? 'Deactivate' : 'Reactivate'}
                            id={`${
                                org.isActive
                                    ? `org-${org.organizationId}-deactivate`
                                    : `org-${org.organizationId}-activate`
                            }`}
                            icon={org.isActive ? faCirclePause : faCirclePlay}
                        />
                    ));

                    org.actions = [
                        <EditAction
                            key={`edit-${org.organizationId}`}
                            isActive={org.isActive}
                            onClick={e => handleCreateOrEditOrgClick(e, org.organizationId)}
                            label={'Edit Information'}
                        />,
                        <UpdateStatusAction
                            key={`status-${org.organizationId}`}
                            isActive={!testOrganizations.includes(parseInt(org.organizationId))}
                            title={
                                testOrganizations.includes(parseInt(org.organizationId))
                                    ? messages.deactivationDisabledForTestOrgs
                                    : ''
                            }
                            label={org.isActive ? 'Deactivate' : 'Activate'}
                            onClick={e => handleUpdateOrgStatusClick(e, org.organizationId, org.name, org.isActive)}
                        />,
                        <MergeOrganizationsAction
                            key={`merge-${org.organizationId}`}
                            isActive={org.isActive}
                            label={'Merge Organization'}
                            onClick={e => handleShowMergeOrganization(e, org.organizationId)}
                        />,
                    ];
                }
                org.contactPersonFirstName = org.contactPerson?.firstName || '';
                org.status =
                    org.organizationId === OEC_STAGING_ORG.organizationId || org.isActive ? (
                        <span className="text-success">Active</span>
                    ) : (
                        <span className="text-danger">Deactivated</span>
                    );
                org.statusText = org.isActive ? 'Active' : 'Deactivated';
            });

            if (selectedFilters.length) {
                for (let filter of selectedFilters) {
                    newSortedAndSearchedOrganizations =
                        filter.optionsSelected &&
                        newSortedAndSearchedOrganizations.filter(u =>
                            filter.optionsSelected.some(x => x.toLowerCase() == u[filter.columnName].toLowerCase())
                        );
                }
            }

            setDisplayOrgs(newSortedAndSearchedOrganizations);
        }
    }, [
        sortedPropertyKey,
        sortedPropertyDirection,
        organizations,
        searchTerm,
        userHasCustomerSupportRole,
        handleRowClick,
        numStagedComps,
        handleCreateOrEditOrgClick,
        useProtoType,
        handleShowMergeOrganization,
        handleUpdateOrgStatusClick,
        selectedFilters,
    ]);

    const fetchOrganizations = useCallback(
        async (orgId, searchParamText) => {
            let res = [];

            if (searchParamText) {
                setSelectedFilters([]);
                const searchRes = await ApiService.post(ApiEndpoints.organization.globalSearch, {
                    searchText: searchParamText,
                    searchType: globalSearchType.organization,
                });
                res = searchRes?.organization?.results ?? [];
            } else if (orgId) {
                setSelectedFilters([]);
                const org = await ApiService.get(ApiEndpoints.organization.fetchById(orgId));
                res = [org];
            } else {
                res = await ApiService.get(ApiEndpoints.organization.fetch);
            }

            const orgs = res.map(o => ({
                ...o,
                countryName: o.country?.countryName || '',
                segmentName: o.segment?.segmentName || '',
                status: o.isActive ? 'Active' : 'Deactivated',
            }));

            const stagedComps = await requestOrganizationCompanies(OEC_STAGING_ORG.organizationId);
            const numStagedComps = stagedComps.length;
            if (orgs.some(o => o.organizationId === OEC_STAGING_ORG.organizationId) && !userHasCustomerSupportRole) {
                const newSortedOrganizations = orgs.reduce((orgArr, org) => {
                    if (org.organizationId === OEC_STAGING_ORG.organizationId) {
                        org.organizationId = (
                            <div className="icon-wrapper">
                                <FontAwesomeIcon className="text-primary" icon={faThumbTack} />
                                {numStagedComps > 0 && <div className="notification-dot">{numStagedComps}</div>}
                            </div>
                        );
                        org.isStagingOrg = true;
                        org.contactPersonFirstName =
                            org.address =
                            org.city =
                            org.state =
                            org.zip =
                            org.countryName =
                            org.segmentName =
                            org.notes =
                                '';
                        orgArr.unshift(org);
                    } else {
                        org.isStagingOrg = false;
                        orgArr.push(org);
                    }
                    return orgArr;
                }, []);
                setOrganizations(newSortedOrganizations);
                setDisplayOrgs(newSortedOrganizations);
            } else {
                setOrganizations(orgs);
                setDisplayOrgs(orgs);
            }
            if (globalSearchRef.current) {
                globalSearchRef.current.focus();
            }
        },
        [userHasCustomerSupportRole]
    );

    useEffect(() => {
        if (isExternalRequest) return;
        const getOrgs = async () => {
            try {
                incrementLoading();
                await fetchOrganizations(searchParamKey, searchParamText);
            } catch (error) {
                showToast('Could not get organizations');
            } finally {
                decrementLoading();
            }
        };
        getOrgs();
    }, [
        showToast,
        incrementLoading,
        decrementLoading,
        searchParamKey,
        searchParamText,
        isExternalRequest,
        fetchOrganizations,
    ]);

    const handleRowClick = useMemo(() => {
        return clickedObject => {
            const orgId =
                clickedObject.name === OEC_STAGING_ORG.name
                    ? OEC_STAGING_ORG.organizationId
                    : clickedObject.organizationId;
            const selectedOrg = organizations.find(o => o.organizationId === clickedObject.organizationId);
            navigate(`${CUST_TOOLS.orgTool.Path(orgId)}`, {
                state: { allOrgs: organizations, org: selectedOrg, company: null },
            });
        };
    }, [navigate, organizations]);

    const handleShowMergeOrganization = useCallback(
        (e, orgId) => {
            e.stopPropagation();
            setEditOrgId(orgId);
            setShowMergeOrganizationModal(true);
        },
        [setEditOrgId, setShowMergeOrganizationModal]
    );

    const handleCreateOrEditOrgClick = useCallback(
        (e, orgId) => {
            setEditOrgId(orgId);
            setShowModal(true);
            e.stopPropagation();
        },
        [setEditOrgId, setShowModal]
    );

    const onSaveOrEdit = useCallback(async () => {
        try {
            incrementLoading();
            await fetchOrganizations(searchParamKey, searchParamText);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    }, [incrementLoading, decrementLoading, showToast, searchParamKey, searchParamText, fetchOrganizations]);

    const handleToggle = (newOrgId = null) => {
        if (showModal) setEditOrgId(newOrgId);
        if (!newOrgId) setShowModal(!showModal);
    };

    const handleMergeOrganizationToggle = useCallback(() => {
        setShowMergeOrgConfirmationModal(false);
        setShowMergeOrganizationModal(false);
        setEditOrgId(null);
    }, [setShowMergeOrganizationModal, setEditOrgId]);

    const confirmMergeOrganization = useCallback(
        async (originalOrgId, proposedOrgId) => {
            try {
                incrementLoading();
                await mergeOrganizations(originalOrgId, proposedOrgId);
                await movePlansToOrganizations(originalOrgId, proposedOrgId);
                notifications.pushSuccess('Organization merged successfully!');
                await fetchOrganizations(searchParamKey, searchParamText);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                decrementLoading();
            }
        },
        [notifications, incrementLoading, decrementLoading, searchParamKey, searchParamText, fetchOrganizations]
    );

    const sortClickCallback = (retSortedProperty, retSortedPropertyDirection) => {
        setSortedPropertyKey(retSortedProperty);
        setSortedPropertyDirection(retSortedPropertyDirection);
    };

    useEffect(() => {
        let isMounted = true;
        //fetch segments only when we open the create/edit Modal for the first time
        if (showModal && segments.length == 0) {
            const getOrgSegments = async () => {
                try {
                    incrementLoading();
                    const res = await requestOrganizationSegments();
                    const data = res.map(({ segmentId, segmentName }) => ({
                        key: segmentId,
                        value: segmentId,
                        text: segmentName,
                    }));
                    isMounted && setSegments(data);
                } catch (error) {
                    notifications.pushExceptionDanger(error);
                } finally {
                    decrementLoading();
                }
            };
            getOrgSegments();
        }
        return () => {
            isMounted = false;
        };
    }, [showModal, notifications, incrementLoading, decrementLoading, segments]);

    const handleUpdateOrgStatusClick = useCallback(
        (e, orgId, orgName, isActive) => {
            e.stopPropagation();
            setSelectedOrgData({ orgId, orgName, isActive, showConfirmationModal: true });
        },
        [setSelectedOrgData]
    );

    const handleUpdateOrgActiveStatus = useCallback(async () => {
        if (!selectedOrgData?.orgId) return;

        try {
            incrementLoading();
            await ApiService.patch(
                ApiEndpoints.organization.updateActiveStatus(selectedOrgData.orgId, !selectedOrgData.isActive)
            );

            setSelectedOrgData(null);
            notifications.pushSuccess(
                `${selectedOrgData.orgName} ${selectedOrgData.isActive ? `deactivated` : `reactivated`} successfully!`
            );

            onSaveOrEdit();
        } catch (error) {
            notifications.pushExceptionDanger('Request has been failed. Please try again.');
        } finally {
            decrementLoading();
        }
    }, [setSelectedOrgData, onSaveOrEdit, incrementLoading, decrementLoading, selectedOrgData, notifications]);

    const filterChangeCallback = useCallback(filters => {
        setSelectedFilters(prevFilters => {
            let updatedFilters = prevFilters.filter(filter => filter.columnName != filters.columnName);
            if (filters.optionsSelected.length) {
                updatedFilters = [...updatedFilters, filters];
            }
            return updatedFilters;
        });
    }, []);

    return {
        tableColumnDetails,
        showModal,
        editOrgId,
        setEditOrgId,
        searchTerm,
        displayOrgs,
        userHasCustomerSupportRole,
        onSaveOrEdit,
        handleToggle,
        setSearchTerm,
        setShowModal,
        handleRowClick,
        sortClickCallback,
        handleCreateOrEditOrgClick,
        segments,
        handleMergeOrganizationToggle,
        showMergeOrganizationModal,
        showMergeOrgConfirmationModal,
        confirmMergeOrganization,
        organizations,
        selectedOrgData,
        handleUpdateOrgActiveStatus,
        setSelectedOrgData,
        searchParamText,
        filterChangeCallback,
        selectedFilters,
        globalSearchRef,
    };
};

export default useOrganizations;
