import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestHondaProcedure';

const getRulableProperties = (dataSource: DataSource) => [
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.serviceCategoryTypeName',
            displayName: 'Service Category Type Name',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'serviceCategoryTypeName',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.serviceCategoryName',
            displayName: 'Service Category Name',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'serviceCategoryName',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.serviceSystemName',
            displayName: 'Service System Name',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'serviceSystemName',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.componentDTCName',
            displayName: 'Component DTC Name',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'componentDTCName',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.displayName',
            displayName: 'Display Name',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'displayName',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.sitqCode',
            displayName: 'SITQ Code',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'sitqCode',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.sitqDescription',
            displayName: 'SITQ Description',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'sitqDescription',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.publicationTitle',
            displayName: 'Publication Title',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'publicationTitle',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.procedureTypeCode',
            displayName: 'Procedure Type Code',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'procedureTypeCode',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.procedureType',
            displayName: 'Procedure Type',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'procedureType',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.dataGroupName',
            displayName: 'Data Group Name',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'dataGroupName',
        },
        dataSource
    ),
];

export const getHondaProcedureMetadata = (dataSource: DataSource) =>
    buildOemMetadata({
        oemId: 17,
        oemName: 'Honda',
        properties: [
            ...getCommonProperties(dataSource),
            ...getRulableProperties(dataSource),
            buildProcedureProperty(
                {
                    name: OEM_METADATA_PROPERTY + '.hondaProcedureId',
                    displayName: 'Honda Procedure Id',
                    type: PROPERTY_TYPE.number,
                },
                dataSource
            ),
            buildProcedureProperty(
                {
                    name: OEM_METADATA_PROPERTY + '.oemProcedureId',
                    displayName: 'Oem Procedure Id',
                    type: PROPERTY_TYPE.string,
                },
                dataSource
            ),
            buildProcedureProperty(
                {
                    name: OEM_METADATA_PROPERTY + '.version',
                    displayName: 'Version',
                    type: PROPERTY_TYPE.string,
                },
                dataSource
            ),
            buildProcedureProperty(
                {
                    name: OEM_METADATA_PROPERTY + '.htmlFileName',
                    displayName: 'HTML File Name',
                    type: PROPERTY_TYPE.string,
                },
                dataSource
            ),
            ...getLastCommonProperties(dataSource),
        ],
        ruleKey: 'hondaMappingRuleId',
        filters: [...getCommonFilters(dataSource)],
    });

type HondaMappingRule = OemMappingRuleBase & {
    hondaMappingRuleId: number;
};

class _HondaMappingEngineService implements OemEngineService<HondaMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = HONDA_PROCEDURE_METADATA_SQL;
    }

    isRuleValid = (rule: HondaMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: HondaMappingRule): Promise<HondaMappingRule> => {
        const newRule = { ...rule };
        const hondaMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.hondaMappingRuleId = hondaMappingRuleId;

        return newRule;
    };
}

export const HONDA_PROCEDURE_METADATA_SQL = getHondaProcedureMetadata(DataSource.SQL);
export const HONDA_PROCEDURE_METADATA_ES = getHondaProcedureMetadata(DataSource.ES);
export const HondaMappingEngineService = new _HondaMappingEngineService();
