import { Routes, Route } from 'react-router-dom';
import HomePage from 'components/locations/Home/HomePage';
import PageNotFound from 'components/Shared/ErrorPages/PageNotFound';
import AuthorizedElement from './AuthorizedElement';
import MappingProcessRoutes from 'components/locations/MappingProcess/MappingProcessRoutes';
import TaggingProcessRoutes from 'components/locations/TaggingProcess/TaggingProcessRoutes';
import RefreshManagerRoutes from 'components/locations/RefreshManager/RefreshManagerRoutes';
import OtherRoutes from '../components/locations/Other/OtherRoutes';
import VehicleProcessRoutes from 'components/locations/VehicleProcess/VehicleProcessRoutes';
import LegacyRoutes from './LegacyRoutes';
import ManageCustomersRoutes from 'components/locations/ManageCustomers/ManageCustomersRoutes';
import { CUST_TOOLS } from 'components/locations/ManageCustomers/ManageCustomersConstants';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
                path="/mapping-process/*"
                element={
                    <AuthorizedElement resource={'tool.mappingProcess'}>
                        <MappingProcessRoutes />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/tagging-process/*"
                element={
                    <AuthorizedElement resource={'tool.taggingProcess'}>
                        <TaggingProcessRoutes />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/vehicle-process/*"
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess'}>
                        <VehicleProcessRoutes />
                    </AuthorizedElement>
                }
            />
            <Route
                path="refreshmanager/*"
                element={
                    <AuthorizedElement resource={'tool.refreshManager'}>
                        <RefreshManagerRoutes />
                    </AuthorizedElement>
                }
            />
            <Route
                path={`/${CUST_TOOLS.baseTool.route}/*`}
                element={
                    <AuthorizedElement resource={'tool.manageCustomers'}>
                        <ManageCustomersRoutes />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/other/*"
                element={
                    <AuthorizedElement resource={'tool.other'}>
                        <OtherRoutes />
                    </AuthorizedElement>
                }
            />
            <Route path="/mapper/*" element={<LegacyRoutes />} />
            <Route path="/tagger/*" element={<LegacyRoutes />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default AppRoutes;
