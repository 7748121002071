import { PrimaryButton, LightButton } from 'oemiq-common';
import { useEffect, useState, useRef } from 'react';
import useAssign3dModel from './useAssign3dModel';
import Icon from 'components/Shared/Elements/Icon';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import ThreeDModelSearch from './ThreeDModelSearch';

const Assign3dModel = ({ vehicle, active, onToggle, setVisualModalViewerModel }) => {
    const [isShowDropdown, setShowDropdown] = useState(false);
    const confirmRef = useRef();
    const {
        isFindLoading,
        isSaveLoading,
        modelSearchValue,
        setModelSearchValue,
        yearSearchValue,
        setYearSearchValue,
        visualModelOptions,
        onShowDropDown,
        handleFindClick,
        resultsCount,
        handleCancelClick,
        handleVisualModelChange,
        selectedVisualModel,
        handleSaveVehicleVisualModel,
        resetVehicleVisualModel,
    } = useAssign3dModel(vehicle, isShowDropdown, setShowDropdown, onToggle);

    useEffect(() => {
        if (!active) {
            setShowDropdown(false);
        } else {
            onShowDropDown();
        }
    }, [active, setShowDropdown, vehicle, onShowDropDown]);

    return (
        <>
            <div
                id={`assign-3d-model-edit-${vehicle.vehicleId}`}
                data-testid={`assign-3d-model-edit-${vehicle.vehicleId}`}>
                <div className="text-right align-self-center me-3"></div>
                <div className={`dropdown me-3 ${isShowDropdown ? 'show' : ''}`}>
                    <button
                        title="View 3D Model"
                        type="button"
                        className={`${vehicle.visualModel ? 'visible' : 'invisible'} btn btn-primary btn-sm me-2`}
                        onClick={() => setVisualModalViewerModel(vehicle.visualModel)}>
                        <Icon icon="eye" />
                    </button>

                    <PrimaryButton
                        id={`assign-3d-model-toggle-${vehicle.vehicleId}`}
                        data-testid={`assign-3d-model-toggle-${vehicle.vehicleId}`}
                        className={`dropdown-toggle ${vehicle.visualModel ? 'btn-primary' : 'btn-secondary'}`}
                        type="button"
                        onClick={() => (onToggle(vehicle.vehicleId), setShowDropdown(show => !show))}>
                        {vehicle.visualModel ? `${vehicle.visualModel.visualModelName}` : 'Assign 3D Model'}
                    </PrimaryButton>

                    {vehicle.visualModel && (
                        <button
                            title="Unassign"
                            type="button"
                            id={`assign-3d-model-remove-from-${vehicle.vehicleId}`}
                            data-testid={`assign-3d-model-remove-from-${vehicle.vehicleId}`}
                            style={{ marginLeft: '5px' }}
                            className="btn btn-secondary btn-sm me-2"
                            onClick={() =>
                                confirmRef.current.open(() => resetVehicleVisualModel(vehicle.vehicleId), {
                                    title: '',
                                    body: (
                                        <>
                                            Are you sure you want to{' '}
                                            <strong className="text-secondary">
                                                unassign the <i>{vehicle.visualModel.visualModelName}</i> 3D model{' '}
                                            </strong>
                                            from the{' '}
                                            <strong className="text-secondary">
                                                {vehicle.year.yearId} {vehicle.oem.oemName} {vehicle.model.modelName}
                                            </strong>
                                            ?
                                        </>
                                    ),
                                    btnClass: 'btn-warning',
                                    btnText: <>Confirm</>,
                                })
                            }>
                            <Icon icon="times" />
                        </button>
                    )}
                    {active && (
                        <div
                            className={`dropdown-menu dropdown-menu-left bg-light pt-2 pb-3 pe-3 ps-3 ${
                                isShowDropdown ? 'show' : ''
                            }`}
                            aria-labelledby="dropdownMenuButton"
                            style={{ minWidth: '550px', borderRadius: '1.3rem' }}>
                            <div className="d-flex align-items-end">
                                <ThreeDModelSearch
                                    id={vehicle.vehicleId}
                                    loading={isFindLoading}
                                    modelNameSearchValue={modelSearchValue}
                                    modelYearSearchValue={yearSearchValue}
                                    visualModelOptions={visualModelOptions}
                                    visualModelValue={selectedVisualModel}
                                    onModelNameChange={e => setModelSearchValue(e.currentTarget.value)}
                                    onModelYearChange={e => setYearSearchValue(e.currentTarget.value)}
                                    onVisualModelChange={e => handleVisualModelChange(e)}
                                />

                                <div className="mb-4 text-left">
                                    <PrimaryButton
                                        type="button"
                                        disabled={isFindLoading}
                                        onClick={handleFindClick}
                                        className="me-2">
                                        Find
                                    </PrimaryButton>
                                </div>
                            </div>
                            <div className="mb-2" style={{ marginRight: '10px' }}>
                                <div className="d-flex justify-content-end">
                                    <LightButton
                                        className="mx-2"
                                        type="button"
                                        onClick={() => (setShowDropdown(false), handleCancelClick())}>
                                        Cancel
                                    </LightButton>

                                    <PrimaryButton
                                        className="mx-2"
                                        id={`assign-3d-model-apply-to-${vehicle.vehicleId}`}
                                        data-testid={`assign-3d-model-apply-to-${vehicle.vehicleId}`}
                                        type="button"
                                        disabled={resultsCount === 0 || isSaveLoading}
                                        onClick={handleSaveVehicleVisualModel}>
                                        Apply
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Confirmation ref={confirmRef} />
        </>
    );
};

export default Assign3dModel;
