import { isNil } from 'lodash';
import { Operator, OPERATOR } from './Operators';
import { PROPERTY_TYPE, PropertyType } from './PropertyType';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';
import { match } from 'ts-pattern';

export type ProcedurePropertyMetadata<TProperty = unknown, TValue = TProperty> = {
    id: string;
    name: string;
    displayName: string;
    type: PropertyType<TValue>;
    enabled: boolean;
    searchable: boolean;
    sortable: boolean;
    orderName: string;
    ruleable: boolean;
    rulePropertyName: string;
    ruleableOperators: Operator<TValue>[];
    multiple: boolean;
    valueProvider: (v: TProperty) => TValue;
};

export type InputMetadata<TProperty = unknown, TValue = TProperty> = {
    id?: string;
    name: string;
    displayName?: string;
    type?: PropertyType<TValue>;
    enabled?: boolean;
    searchable?: boolean;
    sortable?: boolean;
    ruleable?: boolean;
    rulePropertyName?: string;
    ruleableOperators?: Operator<TValue>[];
    multiple?: boolean;
    valueProvider?: (v: TProperty) => TValue;
    es?: { orderName?: string };
    sql?: { orderName?: string };
};

export const buildProcedureProperty = <TProperty = unknown, TValue = TProperty>(
    metadataProperties: InputMetadata<TProperty, TValue>,
    dataSource?: DataSource
): ProcedurePropertyMetadata<TProperty, TValue> => {
    const {
        id,
        name,
        displayName,
        type,
        enabled,
        searchable,
        sortable,
        ruleable,
        rulePropertyName,
        ruleableOperators,
        multiple,
        valueProvider,
        es,
        sql,
    } = metadataProperties;

    const isEnabled = enabled ?? true;
    const isRuleable = ruleable ?? !isNil(rulePropertyName);

    const basePropertyMetadata = {
        id: id ?? name,
        name,
        displayName: displayName ?? name,
        type: type ?? (PROPERTY_TYPE.string as unknown as PropertyType<TValue>),
        enabled: isEnabled,
        searchable: isEnabled && (searchable ?? true),
        sortable: isEnabled && (sortable ?? true),
        ruleable: isRuleable,
        rulePropertyName: isRuleable ? rulePropertyName ?? name : null,
        ruleableOperators: isRuleable ? ruleableOperators ?? [OPERATOR.eq] : [],
        multiple: multiple ?? false,
        valueProvider: valueProvider ?? (v => v as unknown as TValue),
    };

    const dataSourceMetadata = () => {
        return match(dataSource)
            .with(DataSource.SQL, () => ({ orderName: sql?.orderName ? sql.orderName : name }))
            .with(DataSource.ES, () => ({ orderName: es?.orderName ? es.orderName : name }))
            .otherwise(() => ({ orderName: name }));
    };

    const mergedMetadata = { ...basePropertyMetadata, ...dataSourceMetadata() };

    return mergedMetadata;
};
