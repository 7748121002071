export enum OemId {
    Ford = 1,
    Chrysler = 2,
    Toyota = 3,
    Nissan = 4,
    BMW = 5,
    Lexus = 6,
    Scion = 7,
    Chevrolet = 8,
    Cadillac = 9,
    Buick = 10,
    GMC = 11,
    Hummer = 12,
    Oldsmobile = 13,
    Pontiac = 14,
    Saturn = 15,
    Infiniti = 16,
    Honda = 17,
    Acura = 18,
    Hyundai = 19,
    Kia = 20,
    MINI = 21,
    MercedesBenz = 22,
    Subaru = 23,
    Mazda = 24,
    Audi = 25,
    Volkswagen = 26,
    Volvo = 27,
    RAM = 28,
    Fiat = 29,
    Dodge = 30,
    Jeep = 31,
    Jaguar = 32,
    LandRover = 33,
    Mitsubishi = 34,
    Lincoln = 35,
    AlfaRomeo = 36,
    Genesis = 37,
    OEMiQ = 100,
}

const gmOemIds = [
    OemId.Chevrolet,
    OemId.Cadillac,
    OemId.Buick,
    OemId.GMC,
    OemId.Hummer,
    OemId.Oldsmobile,
    OemId.Pontiac,
    OemId.Saturn,
];

export const isGmOemId = (oemId: OemId) => gmOemIds.includes(oemId);
