import { Group } from 'api/RepairProcedures/types';
import { FC } from 'react';
import { InputGroupNameEqualsOperator } from './Operators.Components';

export class Operator<TValue> {
    name: string;
    displayName: string;
    buildFilter: (propertyName: string, value: TValue) => string;
    inputComponent?: FC;

    constructor({
        name,
        displayName,
        buildFilter,
        inputComponent = null,
    }: {
        name: string;
        displayName?: string;
        buildFilter?: (propertyName: string, value: TValue) => string;
        inputComponent?: FC;
    }) {
        this.name = name;
        this.displayName = displayName ?? name;
        this.buildFilter =
            buildFilter ??
            ((propertyName: string, value: TValue): string => encodeURIComponent(`${propertyName} ${name} ${value}`));
        this.inputComponent = inputComponent;
    }
}

export const OPERATOR = {
    contains: new Operator<string>({
        name: 'contains',
        buildFilter: (propertyName, value) => encodeURIComponent(`contains(${propertyName}, ${value})`),
    }),
    notContains: new Operator<string>({
        name: 'not contains',
        buildFilter: (propertyName, value) => encodeURIComponent(`not contains(${propertyName}, ${value})`),
    }),
    eq: new Operator<unknown>({ name: 'eq', displayName: '==' }),
    ne: new Operator<unknown>({ name: 'ne', displayName: '!=' }),
    lt: new Operator<string | number>({ name: 'lt', displayName: '<' }),
    le: new Operator<string | number>({ name: 'le', displayName: '<=' }),
    gt: new Operator<string | number>({ name: 'gt', displayName: '>' }),
    ge: new Operator<string | number>({ name: 'ge', displayName: '>=' }),
};

export const GROUP_OPERATOR = {
    containsAny: new Operator<Group[]>({
        name: 'contains any',
        buildFilter: (propertyName, value) =>
            `${propertyName}/any(g: g/groupId in (${value.map(g => g.groupId).join(', ')}))`,
    }),
    containsAll: new Operator<Group[]>({
        name: 'contains all',
        buildFilter: (propertyName, value) =>
            `(${value.map(g => `${propertyName}/any(g: g/groupId eq ${g.groupId})`).join(' and ')})`,
    }),
    eq: new Operator<Group[]>({
        name: 'equal without order',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(g => `${propertyName}/any(g: g/groupId eq ${g.groupId})`)
                .join(' and ')} and ${propertyName}/$count eq ${value.length})`,
    }),
    nameContains: new Operator<Group[]>({
        name: 'name contains',
        buildFilter: (propertyName, value) =>
            `${propertyName}/any(g: g/groupId in (${value.map(g => g.groupId).join(', ')}))`,
        inputComponent: InputGroupNameEqualsOperator,
    }),
};

export const ALL_OPERATORS = Object.values(OPERATOR);
