import { useContext } from 'react';
import { Route, Link, Routes } from 'react-router-dom';
import RefreshManagerNavigation from 'components/Navigation/RefreshManagerNavigation';
import MappingProcessNavigation from 'components/Navigation/MappingProcessNavigation';
import TaggingProcessNavigation from 'components/Navigation/TaggingProcessNavigation';
import VehicleProcessNavigation from 'components/Navigation/VehicleProcessNavigation';
import ManageCustomersNavigation from 'components/Navigation/ManageCustomersNavigation';
import { CUST_TOOLS } from 'components/locations/ManageCustomers/ManageCustomersConstants';
import OtherNavigation from './OtherNavigation';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import MappingRulesRunIndicator from './MappingRulesRunIndicator/MappingRulesRunIndicator';

const Nav = () => {
    const { userInfo } = useContext(AccessControlContext);
    return (
        <header className="sticky-top">
            <nav
                id="header-navbar"
                className="d-flex align-items-center justify-content-between navbar navbar-expand-sm navbar-dark bg-primary">
                <div className="d-flex align-items-center">
                    <Link
                        className="navbar-brand ms-2"
                        to="/"
                        title="Repair Deck Home"
                        data-testid="navigation-bar-brand">
                        <img src="/images/repairdeck-logo.svg" alt="Repair Deck" />
                    </Link>
                    <Routes>
                        <Route path="*" element={<></>} />
                        <Route path="mapping-process/" element={<MappingProcessNavigation />}>
                            <Route path=":mappingTool" element={<MappingProcessNavigation />} />
                            <Route path=":mappingTool/:oemId" element={<MappingProcessNavigation />} />
                            <Route path=":mappingTool/:oemId/book-list" element={<MappingProcessNavigation />} />
                            <Route path=":mappingTool/:oemId/:bookId" element={<MappingProcessNavigation />} />
                        </Route>
                        <Route path="tagging-process/" element={<TaggingProcessNavigation />}>
                            <Route path=":taggingTool" element={<TaggingProcessNavigation />} />
                            <Route path=":taggingTool/:oemId" element={<TaggingProcessNavigation />} />
                            <Route path=":taggingTool/:oemId/bulkedit" element={<TaggingProcessNavigation />} />
                            <Route path=":taggingTool/:oemId/:bookId" element={<TaggingProcessNavigation />} />
                            <Route
                                path=":taggingTool/:oemId/:bookId/tag/:procedureId"
                                element={<TaggingProcessNavigation />}
                            />
                            <Route
                                path=":taggingTool/:oemId/:bookId/link/:tagId"
                                element={<TaggingProcessNavigation />}
                            />
                        </Route>
                        <Route path="vehicle-process/" element={<VehicleProcessNavigation />}>
                            <Route path=":vehicleTool" element={<VehicleProcessNavigation />} />
                            <Route path=":vehicleTool/:oemId" element={<VehicleProcessNavigation />} />
                            <Route path=":vehicleTool/:oemId/:modelId" element={<VehicleProcessNavigation />} />
                        </Route>
                        <Route path="refreshmanager/" element={<RefreshManagerNavigation />}>
                            <Route path=":subtool" element={<RefreshManagerNavigation />} />
                            <Route path=":subtool/:oemId" element={<RefreshManagerNavigation />} />
                            <Route path=":subtool/:oemId/:importJobId" element={<RefreshManagerNavigation />} />
                        </Route>
                        <Route path={`${CUST_TOOLS.baseTool.route}`} element={<ManageCustomersNavigation />}>
                            <Route path=":customerTool" element={<ManageCustomersNavigation />}>
                                <Route path=":organizationId" element={<ManageCustomersNavigation />}>
                                    <Route path=":orgTool" element={<ManageCustomersNavigation />}>
                                        <Route path=":locationId" element={<ManageCustomersNavigation />}>
                                            <Route path=":locTool" element={<ManageCustomersNavigation />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="other/" element={<OtherNavigation />}>
                            <Route path=":otherTool" element={<OtherNavigation />} />
                            <Route path={':otherTool/:oemId'} element={<OtherNavigation />} />
                            <Route path={':otherTool/:oemId/:bookId'} element={<OtherNavigation />} />
                        </Route>
                    </Routes>
                </div>
                <div className="d-flex flex-row">
                    <MappingRulesRunIndicator />
                    {userInfo.userId && (
                        <div
                            id="logged-username"
                            className="text-white p-2">{`${userInfo.firstName} ${userInfo.lastName}`}</div>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default Nav;
