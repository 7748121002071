import React from 'react';
import { PrimaryButton, LightButton } from 'oemiq-common';
import useColumnSettings from './useColumnSettings';
import ColumnSettingItem from './ColumnSettingItem';
import { useCallback } from 'react';
import './column-settings.scss';

export type DynamicProcedureKey = {
    id: string;
    isHidden: boolean;
    text: string;
    width: string;
};

type ColumnSettingsModalProps = {
    setShowColumnSettings: (x: boolean) => void;
    dynamicProcedureKeys: DynamicProcedureKey[];
    onSaveColumnSettings: (x: DynamicProcedureKey[]) => void;
    hideDynamicKeyList: string[];
};

const ColumnSettingsModal = ({
    setShowColumnSettings,
    dynamicProcedureKeys,
    onSaveColumnSettings,
    hideDynamicKeyList,
}: ColumnSettingsModalProps) => {
    const {
        localDynamicProcedureKeys,
        handleHiddenColumnChange,
        columnDragStart,
        columnDragOver,
        columnDragEnd,
        columnDrop,
        columnDragEnter,
        dropColumnId,
    } = useColumnSettings(dynamicProcedureKeys, hideDynamicKeyList);

    const handleCancel = useCallback(() => {
        setShowColumnSettings(false);
    }, [setShowColumnSettings]);

    const handleSave = useCallback(() => {
        setShowColumnSettings(false);
        onSaveColumnSettings(localDynamicProcedureKeys);
    }, [localDynamicProcedureKeys, onSaveColumnSettings, setShowColumnSettings]);

    return (
        <>
            <div className="column-settings-modal border card-shadow">
                <div className="p-3">
                    <h6>Column Settings</h6>
                    <div className="mb-3">
                        {localDynamicProcedureKeys.map(d => (
                            <ColumnSettingItem
                                key={d.id}
                                columnId={d.id}
                                text={d.text}
                                isHidden={d.isHidden}
                                dropColumnId={dropColumnId}
                                columnDragEnter={columnDragEnter}
                                columnDrop={columnDrop}
                                columnDragOver={columnDragOver}
                                onHiddenColumnChange={handleHiddenColumnChange}
                                columnDragStart={columnDragStart}
                                columnDragEnd={columnDragEnd}
                            />
                        ))}
                    </div>
                    <div className="d-flex justify-content-between">
                        <LightButton type="button" id="column-settings-cancel" onClick={handleCancel}>
                            Cancel
                        </LightButton>
                        <PrimaryButton type="button" id="column-settings-save" onClick={handleSave}>
                            Save
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </>
    );
};

type ColumnSettingProps = {
    showColumnSettings: boolean;
} & ColumnSettingsModalProps;

const ColumnSettings = ({
    showColumnSettings,
    setShowColumnSettings,
    dynamicProcedureKeys,
    onSaveColumnSettings,
    hideDynamicKeyList,
}: ColumnSettingProps) => {
    return showColumnSettings ? (
        <ColumnSettingsModal
            setShowColumnSettings={setShowColumnSettings}
            dynamicProcedureKeys={dynamicProcedureKeys}
            onSaveColumnSettings={onSaveColumnSettings}
            hideDynamicKeyList={hideDynamicKeyList}
        />
    ) : null;
};

export default ColumnSettings;
