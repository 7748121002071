import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestSubaruProcedure';

const getRulableProperties = (dataSource: DataSource) => [
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.level1',
            displayName: 'Level 1',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'level1',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.level2',
            displayName: 'Level 2',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'level2',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.level3',
            displayName: 'Level 3',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'level3',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.level4',
            displayName: 'Level 4',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'level4',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.bookType',
            displayName: 'Book type',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'bookType',
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.procedureTitle',
            displayName: 'Procedure title',
            type: PROPERTY_TYPE.string,
            rulePropertyName: 'procedureTitle',
        },
        dataSource
    ),
];

const getNonRulableProperties = (dataSource: DataSource) => [
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.subaruProcedureId',
            displayName: 'Subaru Procedure Id',
            type: PROPERTY_TYPE.number,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.oemProcedureId',
            displayName: 'Oem Procedure Id',
            type: PROPERTY_TYPE.string,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.version',
            displayName: 'Version',
            type: PROPERTY_TYPE.string,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.htmlFileName',
            displayName: 'Html File Name',
            type: PROPERTY_TYPE.string,
        },
        dataSource
    ),
    buildProcedureProperty(
        {
            name: OEM_METADATA_PROPERTY + '.htmlSelector',
            displayName: 'Html Selector',
            type: PROPERTY_TYPE.string,
        },
        dataSource
    ),
];

export const getSubaruProcedureMetadata = (dataSource: DataSource) =>
    buildOemMetadata({
        oemId: 23,
        oemName: 'Subaru',
        properties: [
            ...getCommonProperties(dataSource),
            ...getRulableProperties(dataSource),
            ...getNonRulableProperties(dataSource),
            ...getLastCommonProperties(dataSource),
        ],
        ruleKey: 'subaruMappingRuleId',
        filters: [...getCommonFilters(dataSource)],
    });

type SubaruMappingRule = OemMappingRuleBase & {
    subaruMappingRuleId: number;
};

class _SubaruMappingEngineService implements OemEngineService<SubaruMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = SUBARU_PROCEDURE_METADATA_SQL;
    }

    isRuleValid = (rule: SubaruMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: SubaruMappingRule): Promise<SubaruMappingRule> => {
        const newRule = { ...rule };
        const subaruMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.subaruMappingRuleId = subaruMappingRuleId;

        return newRule;
    };
}
export const SUBARU_PROCEDURE_METADATA_SQL = getSubaruProcedureMetadata(DataSource.SQL);
export const SUBARU_PROCEDURE_METADATA_ES = getSubaruProcedureMetadata(DataSource.ES);
export const SubaruMappingEngineService = new _SubaruMappingEngineService();
