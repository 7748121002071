import { Number } from './Number';
import { operatorsList } from '../operators';

export default {
    component: Number,
    format: v => v,
    formatES: v => v,
    operators: [operatorsList.eqBookId],
    defaultValue: 0,
    allowFalse: false,
    allowInstances: 1,
};
