import getOemService from 'hooks/OemModels/useOemService';

export interface Column {
    fieldName: string;
    displayName: string;
}
export interface OemDataModel {
    keyField: string;
    columns: Column[];
}
export interface OemRulesMetadata {
    oemId: number;
    oemName: string;
    dataModel: OemDataModel;
}

const cache = new Map<number | string, OemDataModel>();

export const getOemDataModel = (oemId: number | string): OemDataModel => {
    if (cache.has(oemId)) return cache.get(oemId);

    const { oemMetadata } = getOemService(oemId);
    if (!oemMetadata) throw `No metamodel defined for OEM Id = ${oemId}`;

    const dataModel: OemDataModel = {
        keyField: oemMetadata.ruleKey,
        columns: oemMetadata.ruleable.map(p => {
            return { fieldName: p.rulePropertyName, displayName: p.displayName };
        }),
    };

    cache.set(oemId, dataModel);

    return dataModel;
};
