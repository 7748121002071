import React from 'react';
import { Input } from 'oemiq-common';

const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
const isProperDateFormat = value => dateRegex.test(value);

const Date = ({ filter, setFilterValue }) => {
    return (
        <Input
            className="mb-2"
            type="text"
            placeholder="Filter date (format: YYYY-MM-DD)"
            disabled={!filter.id || !filter.operator}
            value={filter.value}
            onChange={e => {
                setFilterValue({ value: e.target.value, valueList: null });
            }}
        />
    );
};

const operatorsList = [
    {
        value: 'eqdate',
        label: '==',
        buildESFilter: (propertyName, value) => `${propertyName}:${value}`,
        buildFilter: (propertyName, value) => `${propertyName} eq ${value}`,
    },
    {
        value: 'ne',
        label: '!=',
        buildESFilter: (propertyName, value) => `!${propertyName}:${value}`,
        buildFilter: (propertyName, value) => `${propertyName} ne ${value}`,
    },
    {
        value: 'lt',
        label: '<',
        buildESFilter: (propertyName, value) => `${propertyName}:<${value}`,
        buildFilter: (propertyName, value) => `${propertyName} lt ${value}`,
    },
    {
        value: 'le',
        label: '<=',
        buildESFilter: (propertyName, value) => `${propertyName}:<=${value}`,
        buildFilter: (propertyName, value) => `${propertyName} le ${value}`,
    },
    {
        value: 'gt',
        label: '>',
        buildESFilter: (propertyName, value) => `${propertyName}:>${value}`,
        buildFilter: (propertyName, value) => `${propertyName} gt ${value}`,
    },
    {
        value: 'ge',
        label: '>=',
        buildESFilter: (propertyName, value) => `${propertyName}:>=${value}`,
        buildFilter: (propertyName, value) => `${propertyName} ge ${value}`,
    },
];

export default {
    component: Date,
    format: v => v,
    formatES: v => v,
    validator: isProperDateFormat,
    operators: operatorsList,
    defaultValue: '',
    allowFalse: false,
    allowInstances: 2,
};
