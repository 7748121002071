import React from 'react';
import InfiniteScrollTableRow from 'components/InfiniteScrollTableRow';
import { OemProceduresHeaderCell } from './items/OemProceduresHeaderCell';
import { LargeCheckbox } from 'oemiq-common';
import { OemMetadata } from 'hooks/OemModels/MetaModel/BuildOemMetadata';
import { HistoryModalProcedure } from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';
import { ESProcedure } from 'components/locations/MappingProcess/Procedures/ESProcedure';

type OemProceduresTableProps = BaseProps & (Editable | NoEditable);

type BaseProps = {
    data: ESProcedure[];
    hasMoreData: boolean;
    oemMetadata: OemMetadata;
    style?;
    columnSettings: {
        id: string;
        isHidden: boolean;
        text: string;
        width: string;
    }[];
    isLoading: boolean;
    onSorting: (columnName: string, order: string) => void;
    loadMoreCallback: () => void;
    onClickModalProcedure;
    onclickHistoryModalProcedure: (proc: HistoryModalProcedure | null) => void;
};

type Editable = {
    isEditable: true;
    selected;
    isAll: boolean;
    handleSelectAll: (data) => void;
    handleSelectOne: (procedureId) => void;
    refreshProcedures;
    setNewGroupListToProcedureByProcedureId;
};

type NoEditable = {
    isEditable: false;
};

const OemProceduresTable = (props: OemProceduresTableProps) => {
    const {
        data,
        hasMoreData,
        oemMetadata,
        style,
        columnSettings,
        isLoading,
        onSorting,
        loadMoreCallback,
        onClickModalProcedure,
        onclickHistoryModalProcedure,
    }: BaseProps = props;

    const visibleColumns = columnSettings
        .filter(c => !c.isHidden)
        .map(cs => oemMetadata.properties.find(dt => dt.id === cs.id))
        .filter(c => c);

    return (
        <div id="procedures-table" className="overflow-auto flex-grow-1" style={style}>
            <table className="table mt-3">
                <thead className="sticky-top bg-white">
                    <tr>
                        {props.isEditable && (
                            <th>
                                <LargeCheckbox
                                    id={'bulk-select-all'}
                                    label=""
                                    className="large-checkbox-check mb-0"
                                    checked={props.isAll}
                                    onChange={() => props.handleSelectAll(data)}
                                    readOnly={false}
                                />
                            </th>
                        )}
                        {visibleColumns.map(column => (
                            <OemProceduresHeaderCell key={column.id} element={column} onSorting={onSorting} />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.map((row, i) => {
                            const isRemovalPending = row?.stageArea.isDeleted && !row.isDeleted;
                            const isRemoved = row?.stageArea.isDeleted && row.isDeleted;

                            return (
                                <tr
                                    key={i}
                                    className={
                                        isRemovalPending
                                            ? 'bg-red-200'
                                            : isRemoved
                                            ? 'bg-gray-500'
                                            : row?.isStale
                                            ? 'bg-yellow-500'
                                            : ''
                                    }>
                                    {props.isEditable && (
                                        <td>
                                            <div>
                                                <LargeCheckbox
                                                    id={`bulk-select-${row.procedureId}`}
                                                    label=""
                                                    className="large-checkbox-check mb-0"
                                                    checked={props.selected.includes(row.procedureId)}
                                                    disabled={props.isAll || isRemovalPending || isRemoved}
                                                    onChange={() => props.handleSelectOne(row.procedureId)}
                                                    readOnly={false}
                                                />
                                            </div>
                                        </td>
                                    )}
                                    {visibleColumns.map(column => {
                                        const value = column.name.split('.').reduce((o, i) => (o ? o[i] : null), row);
                                        return (
                                            <td key={column.id} headers={column.id}>
                                                <column.type.viewComponent
                                                    value={value}
                                                    row={row}
                                                    column={column}
                                                    onClick={onClickModalProcedure}
                                                    onHistoryClick={onclickHistoryModalProcedure}
                                                    refreshProcedures={props.isEditable && props.refreshProcedures}
                                                    setNewGroupListToProcedureByProcedureId={
                                                        props.isEditable &&
                                                        props.setNewGroupListToProcedureByProcedureId
                                                    }
                                                    clickable={props.isEditable && !isRemovalPending && !isRemoved}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
                <tfoot>
                    {hasMoreData && (
                        <InfiniteScrollTableRow
                            columnsCount={columnSettings.length}
                            isLoading={isLoading}
                            loadMoreCallback={loadMoreCallback}
                        />
                    )}
                </tfoot>
            </table>
        </div>
    );
};

export default OemProceduresTable;
